import { HttpMethod } from "../types/httpMethod";
import {ApiTag} from "../types/apiTag";

export const carrierEndpoints = (builder) => ({
    getDocumentByPlanId: builder.query({

        query: (planId) => ({
            url: `/Carrier/GetDocumentByPlanId/${encodeURIComponent(planId)}`,
            method: HttpMethod.Get
        }),
        /**
         * @returns {CarrierPlanDocDTO}
         */
        transformResponse: (response) => response
    }),
    getDocumentsByPlanId: builder.query({

        query: (planId) => ({
            url: `/Carrier/GetDocumentsByPlanId/${encodeURIComponent(planId)}`,
            method: HttpMethod.Get
        }),
        /**
         * @returns {CarrierPlanDocDTO[]}
         */
        transformResponse: (response) => {
            if (!(response instanceof Array)) {
                return [];
            }
            return response;
        }
    }),
    upsertCarrierDocument: builder.mutation({
        /**
         * @param {Object} upsertCarrierDocumentRequest
         */
        query: (upsertCarrierDocumentRequest) => ({
            url: '/Carrier/UpsertCarrierDocument',
            method: HttpMethod.Post,
            data: upsertCarrierDocumentRequest
        }),
    }),
    getCarrierRatesByPlanId: builder.query({
        query: (request) => ({
            url: `/api/InternalRates/Get/CarrierRates?planId=${request.planId}`,
            method: HttpMethod.Get
        }),
        transformResponse: (response) => {
            if (response?.success && Array.isArray(response.data)) {
                return response.data;
            }
            return [];
        },
    }),
    getLogoByPlanId: builder.query({
        /**
         * @param {GetLogoByPlanIdRequest} getLogoByPlanIdRequest
         */
        query: (getLogoByPlanIdRequest) => ({
            url: `/Carrier/GetLogoByPlanId/${encodeURIComponent(getLogoByPlanIdRequest.planId)}`,
            method: HttpMethod.Get
        }),
        /**
         * @returns {GetLogoByPlanIdResponse}
         */
        transformResponse: (response) => response,
    }),
    getCarrierLogo: builder.query({
        /**
         * @param {GetCarrierLogoRequest} getCarrierLogoRequest
         */
        query: (getCarrierLogoRequest) => ({
            url: `/Carrier/GetCarrierLogo/${encodeURIComponent(getCarrierLogoRequest.logoId)}`,
            method: HttpMethod.Get
        }),
        /**
         * @returns {GetCarrierLogoResponse}
         */
        transformResponse: (response) => response,
    }),
    upsertCarrierLogo: builder.mutation({
        /**
         * @param {UpsertCarrierLogoRequest} upsertCarrierLogoRequest
         */
        query: (upsertCarrierLogoRequest) => ({
            url: '/Carrier/UpsertCarrierLogo',
            method: HttpMethod.Post,
            data: upsertCarrierLogoRequest
        }),
    }),

    upsertCarrierPlan: builder.mutation({
        query: ({
                    planId,
                    carrierId,
                    productName,
                    description,
                    minEmployees,
                    maxEmployees,
                    availableNationwide,
                    applicableStates,
                    benefitTypeId,
                    global,
                    isActive
                }) => ({
            url: '/Carrier/UpsertCarrierPlan',
            method: HttpMethod.Post,
            data: {
                planId,
                carrierId, // Ensure carrierId is correctly passed here
                productName,
                description,
                minEmployees,
                maxEmployees,
                availableNationwide,
                applicableStates,
                benefitTypeId,
                global,
                isActive // Add `isActive` if required
            }
        }),
    }),

    upsertCarrierRate: builder.mutation({
        query: (upsertCarrierRateRequest) => ({
            url: '/Carrier/UpsertCarrierRate',
            method: HttpMethod.Post,
            data: upsertCarrierRateRequest,
        }),
    }),
    /**
     * Retrieves all carrier plans associated with a given carrier ID.
     * @param {number} carrierId - The ID of the carrier.
     * @returns {CarrierPlanDTO[]} - A list of CarrierPlanDTOs.
     */
    getCarrierPlansByCarrierId: builder.query({
        query: (carrierId) => ({
            url: `/Carrier/GetCarrierPlansByCarrierId/${encodeURIComponent(carrierId)}`,
            method: HttpMethod.Get,
        }),
        /**
         * @returns {CarrierPlanDTO[]}
         */
        transformResponse: (response) => response,
    }),
    upsertCarrierDocumentChunk: builder.mutation({
        query: (chunkData) => ({
            url: '/Carrier/UpsertCarrierDocumentChunk',
            method: HttpMethod.Post,
            data: chunkData
        }),
    }),

    getActiveCarriersByEmployerCode: builder.query({
        query: (employerCode) => ({
            url: `/Carrier/GetActiveCarriersByEmployerCode/${encodeURIComponent(employerCode)}`,
            method: HttpMethod.Get
        }),
        transformResponse: (response) => {
            if (!(response instanceof Array)) {
                console.error('Unexpected response format:', response);
                return [];
            }
            return response;
        },
        providesTags: [ApiTag.Carriers]
    }),
    getAllCarriers: builder.query({
        query: () => ({ url: '/Carrier/GetAllCarriers', method: HttpMethod.Get }),
        transformResponse: (response) => {
            // The response is already an array of carriers, so we don't need to access response.data
            if (!(response instanceof Array)) {
                console.error('Unexpected response format:', response);
                return [];
            }
            return response;
        },
    }),
    upsertCarrier: builder.mutation({
        query: (upsertCarrierRequest) => ({
            url: '/Carrier/UpsertCarrier',
            method: HttpMethod.Post,
            data: upsertCarrierRequest
        }),
        invalidatesTags: [ApiTag.Carriers]
    }),

});
