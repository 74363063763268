import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Container,
    Box,
    Typography,
    Grid,
    TextField,
    Button,
    Paper,
    Snackbar,
    useMediaQuery,
    CircularProgress,
    FormControlLabel,
    Switch
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Alert } from '@mui/material';
import { completeMedicalInfoPage } from "../../reducers/benefitEnrollmentSlice";
import useCustomTranslation from "../../hooks/useCustomTranslation";
import CustomFileUploader from "../PdfUpload/CustomFileUploader";
import Branding from "../Branding";
import logoImage from '../../assets/logo/default_logo.png';
import { useGetQuestionsByPlanIdQuery } from '../../reducers/enrollmentApiSlice';

const EnrollmentMedicalQuestionsPage = ({ onNext }) => {
    const dispatch = useDispatch();
    const { translate } = useCustomTranslation();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const selectedPlans = useSelector((state) => state.selectedPlan);
    const planIds = selectedPlans.map(plan => plan.planId);

    const [answers, setAnswers] = useState({});
    const [loading, setLoading] = useState(false);
    const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
    const [documentsUploaded, setDocumentsUploaded] = useState(false);

    const queries = planIds.map(planId => useGetQuestionsByPlanIdQuery(planId));
    const isLoading = queries.some(query => query.isLoading);
    const isError = queries.some(query => query.isError);
    const planQuestionsResponses = queries.map(query => query.data).filter(Boolean);

    const handleUpload = async (pdfFiles, fileBinaries) => {
        setLoading(true);
        try {
            const data = pdfFiles.map(file => ({
                documentData: fileBinaries[file.name]
            }));
            // Simulating API call
            await new Promise(resolve => setTimeout(resolve, 1000));
            setSnackbar({ open: true, message: translate('Documents uploaded successfully'), severity: 'success' });
            setDocumentsUploaded(true);
        } catch (error) {
            setSnackbar({ open: true, message: translate('Error uploading documents'), severity: 'error' });
        } finally {
            setLoading(false);
        }
    };

    const handleAnswerChange = (questionId, answer) => {
        setAnswers(prevAnswers => ({
            ...prevAnswers,
            [questionId]: answer
        }));
    };

    const handleCompleteAndContinue = () => {
        if (!documentsUploaded) {
            setSnackbar({ open: true, message: translate('Please upload required documents'), severity: 'warning' });
            return;
        }
        const totalQuestions = planQuestionsResponses.flatMap(response => response.data).length;
        if (Object.keys(answers).length !== totalQuestions) {
            setSnackbar({ open: true, message: translate('Please answer all questions'), severity: 'warning' });
            return;
        }
        dispatch(completeMedicalInfoPage());
        onNext();
    };

    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbar({ ...snackbar, open: false });
    };

    if (isLoading) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <CircularProgress />
            </Box>
        );
    }

    if (isError) {
        return (
            <Container component="main" maxWidth="md">
                <Box sx={{ my: 4 }}>
                    <Typography component="h1" variant="h5" sx={{ mb: 3, textAlign: 'center' }}>
                        {translate('An error occurred while loading medical questions')}
                    </Typography>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {
                            dispatch(completeMedicalInfoPage());
                            onNext();
                        }}
                    >
                        {translate('Continue')}
                    </Button>
                </Box>
            </Container>
        );
    }

    const allQuestions = planQuestionsResponses.flatMap(response => response.data || []);

    if (allQuestions.length === 0) {
        return (
            <Container component="main" maxWidth="md">
                <Box sx={{ my: 4 }}>
                    <Typography component="h1" variant="h5" sx={{ mb: 3, textAlign: 'center' }}>
                        {translate('No medical data information required at this time to enroll in the plan')}
                    </Typography>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {
                            dispatch(completeMedicalInfoPage());
                            onNext();
                        }}
                    >
                        {translate('Continue')}
                    </Button>
                </Box>
            </Container>
        );
    }

    return (
        <Container component="main" maxWidth="md">
            <Box sx={{ my: 4 }}>
                <Grid container spacing={3} justifyContent="center">
                    <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                        <Branding logoUrl={logoImage} width={isMobile ? "150px" : "250px"} height={isMobile ? "45px" : "75px"} />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography component="h1" variant="h5" sx={{ mb: 3, textAlign: 'center' }}>
                            {translate('Upload Medical Documents and Answer Questions')}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Paper elevation={3} sx={{ p: 3 }}>
                            <Typography variant="h6" gutterBottom>
                                {translate('Upload Documents')}
                            </Typography>
                            <CustomFileUploader onUpload={handleUpload} disabled={loading} />
                            <FormControlLabel
                                control={<Switch checked={documentsUploaded} disabled />}
                                label={translate('Documents Uploaded')}
                                sx={{ mt: 2 }}
                            />
                        </Paper>
                    </Grid>
                    <Grid item xs={12}>
                        <Paper elevation={3} sx={{ p: 3 }}>
                            <Typography component="h2" variant="h6" gutterBottom>
                                {translate('Medical Questions')}
                            </Typography>
                            {allQuestions.map(question => (
                                <TextField
                                    key={question.questionId}
                                    label={translate(question.questionText)}
                                    variant="outlined"
                                    fullWidth
                                    margin="normal"
                                    value={answers[question.questionId] || ''}
                                    onChange={(e) => handleAnswerChange(question.questionId, e.target.value)}
                                    required
                                    aria-required="true"
                                />
                            ))}
                        </Paper>
                    </Grid>
                    <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleCompleteAndContinue}
                            disabled={loading}
                            startIcon={loading && <CircularProgress size={20} color="inherit" />}
                        >
                            {loading ? translate('Submitting...') : translate('Complete & Continue')}
                        </Button>
                    </Grid>
                </Grid>
            </Box>
            <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={handleCloseSnackbar}>
                <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
                    {snackbar.message}
                </Alert>
            </Snackbar>
        </Container>
    );
};

export default EnrollmentMedicalQuestionsPage;