import AuthTokenService from "../services/AuthTokenService";

// Function to process the upserts of the rates
export const processRateUpserts = async (rates, selectedContributionType, upsertEmployerAdminRate) => {
    let successCount = 0;
    let failureCount = 0;
    let upsertedRates = [];

    const userEmail = AuthTokenService.getAuthInfo().user;

    if (!validateEmail(userEmail)) {
        console.error('Invalid user email:', userEmail);
        return { successCount, failureCount: rates.length, upsertedRates };
    }

    for (const rate of rates) {
        const employerAdminRateDTO = mapRateToDTO(rate, selectedContributionType, userEmail);

        try {
            const response = await upsertEmployerAdminRate(employerAdminRateDTO).unwrap();
            // Assuming the response is an array of upserted rates
            if (Array.isArray(response)) {
                upsertedRates = upsertedRates.concat(response);
                successCount += response.length;
            } else {
                // If the response is a single object
                upsertedRates.push(response);
                successCount++;
            }
        } catch (error) {
            console.error('Upsert error for rate:', employerAdminRateDTO.RateId, 'Error:', error);
            failureCount++;
        }
    }

    return { successCount, failureCount, upsertedRates };
};
// Function to map the rate data to the DTO format expected by the API
const mapRateToDTO = (rate, selectedContributionType, userEmail) => {
    console.log("Mapping rate to DTO:", {
        rateId: rate.rateId,
        employerCode: rate.employerCode,
        contributionTypeId: rate.contributionTypeId,
        selectedContributionType: selectedContributionType,
        contributionValue: rate.contributionValue,
        userEmail: userEmail,
        coverageTier: rate.coverageTier  // Log the coverage tier
    });

    const dto = {
        RateId: rate.rateId === '0' ? 0 : parseInt(rate.rateId, 10),
        EmployerCode: rate.employerCode,
        Rate: parseFloat(rate.rate) || 0,
        SmokingStatus: rate.smokingStatus === null ? null : Boolean(rate.smokingStatus),
        IsActive: Boolean(rate.isActive),
        EffectiveDate: formatDate(rate.effectiveDate),
        ExpirationDate: rate.expirationDate ? formatDate(rate.expirationDate) : null,
        PlanId: parseInt(rate.planId, 10),
        CoverageTier: rate.coverageTier || "",
        RateTypeId: parseInt(rate.rateTypeId, 10),
        RateTypeName: rate.rateTypeName,
        CustomRateString: rate.customRateString || null,
        AgeBand: rate.ageBand || null,
        Email: userEmail,
        Role: rate.role || "employer-admin",
        ContributionTypeId: parseInt(rate.contributionTypeId, 10) || parseInt(selectedContributionType, 10),
        ContributionValue: rate.contributionValue !== null ? parseFloat(rate.contributionValue) : null,
        AcaAffordability: Boolean(rate.acaAffordability),

        // Logging newly added fields
        EoiMax: rate.eoiMax !== undefined ? parseFloat(rate.eoiMax) : null,
        Max: rate.max !== undefined ? parseFloat(rate.max) : null,
        Increments: rate.increments !== undefined ? parseFloat(rate.increments) : null,

        BenefitType: rate.benefitType || null,  // Ensure the benefit type is correctly passed

        BrokerRateId: rate.brokerRateId !== undefined ? parseInt(rate.brokerRateId, 10) : null,
        BasedOnBrokerRate: Boolean(rate.basedOnBrokerRate),
        AgencyRateId: rate.agencyRateId !== undefined ? parseInt(rate.agencyRateId, 10) : null,
        BasedOnAgencyRate: Boolean(rate.basedOnAgencyRate),
        CarrierRateId: rate.carrierRateId !== undefined ? parseInt(rate.carrierRateId, 10) : null,
        BasedOnCarrierRate: Boolean(rate.basedOnCarrierRate),
    };

    return dto;
};

// Helper function to format dates to YYYY-MM-DD format
const formatDate = (dateString) => {
    if (!dateString) {
        return null;
    }
    const date = new Date(dateString);
    const formattedDate = date.toISOString().split('T')[0];
    return formattedDate;
};

// Function to display the result alert based on success and failure counts
export const displayResultAlert = (successCount, failureCount, setAlert) => {
    if (successCount > 0 && failureCount === 0) {
        setAlert({ type: 'success', message: 'Rates upserted successfully!' });
    } else if (failureCount > 0) {
        setAlert({ type: 'error', message: `Failed to upsert ${failureCount} rate(s).` });
    }
};

// Function to reset the alert after a delay
export const resetAlertAfterDelay = (setAlert) => {
    setTimeout(() => {
        setAlert(null);
    }, 10000); // 10 seconds delay to clear the alert
};

// Helper function to validate email format using a regular expression
const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Basic email format validation
    const isValid = emailRegex.test(email);
    return isValid;
};
