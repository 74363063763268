import { HttpMethod } from "../types/httpMethod";

/**
 * @typedef {Object} EmployerAdminRateDTO
 * @property {number} rateId
 * @property {number} adminId
 * @property {number} rate
 * @property {boolean} [smokingStatus]
 * @property {string} effectiveDate
 * @property {string} coverageTier
 * @property {number} rateTypeId
 * @property {string} rateTypeName
 * @property {string} [customRateString]
 * @property {string} [ageBand]
 * @property {string} email
 * @property {string} role
 * @property {number} [brokerRateId]
 * @property {boolean} basedOnBrokerRate
 * @property {number} [agencyRateId]
 * @property {boolean} basedOnAgencyRate
 * @property {number} [carrierRateId]
 * @property {boolean} basedOnCarrierRate
 * @property {number} employerId
 */

/**
 * @typedef {Object} BrokerRateDTO
 * @property {number} rateId
 * @property {number} carrierId
 * @property {number} rate
 * @property {boolean} [smokingStatus]
 * @property {string} effectiveDate
 * @property {number} planId
 * @property {string} coverageTier
 * @property {number} rateTypeId
 * @property {string} rateTypeName
 * @property {string} [customRateString]
 * @property {string} [ageBand]
 * @property {string} email
 * @property {string} role
 * @property {number} [employerId]
 * @property {boolean} basedOnCarrierRate
 * @property {number} [agencyRateId]
 * @property {number} [carrierRateId]
 */

/**
 * @typedef {Object} AgencyRateDTO
 * @property {number} agencyRateId
 * @property {number} agencyId
 * @property {number} carrierRateId
 * @property {number} [customRate]
 * @property {string} effectiveDate
 * @property {string} coverageTier
 * @property {number} rateTypeId
 * @property {string} [customRateString]
 * @property {string} [ageBand]
 * @property {boolean} [smokingStatus]
 * @property {InternalCarrierRateDTO} carrierRate
 * @property {string} agencyName
 * @property {string} rateTypeName
 */

/**
 * @typedef {Object} CarrierRateDTO
 * @property {number} rateId
 * @property {number} carrierId
 * @property {number} rate
 * @property {boolean} smokingStatus
 * @property {string} effectiveDate
 * @property {number} planId
 * @property {string} coverageTier
 * @property {number} rateTypeId
 * @property {string} rateTypeName
 * @property {string} customRateString
 * @property {string} ageBand
 * @property {string} email
 * @property {string} role
 * @property {number|null} employerId
 * @property {boolean} basedOnCarrierRate
 */


/**
 * @typedef {Object} RatesRequest
 * @property {string} email
 * @property {number} planId
 */

/**
 * @typedef {Object} GetEmployerAdminRatesByPlanIdRequest
 * @property {string} email
 * @property {number} planId
 */

/**
 * @typedef {Object} GetAgencyRatesRequest
 * @property {string} email
 * @property {number} planId
 */

/**
 * @typedef {Object} GetBrokerRatesRequest
 * @property {string} email
 * @property {number} planId
 */

// Define the internal rates endpoints
export const internalRatesEndpoints = (builder) => ({
    /**
     * Fetch employer admin rates by plan ID
     * @param {GetEmployerAdminRatesByPlanIdRequest} request
     * @returns {EmployerAdminRateDTO[]}
     */
    getEmployerAdminRatesByPlanId: builder.query({
        query: (request) => ({
            url: `/api/InternalRates/Get/EmployerAdminRates?email=${encodeURIComponent(request.email)}&planId=${request.planId}`,
            method: HttpMethod.Get
        }),
        transformResponse: (response) => {
            if (!(response?.data instanceof Array)) {
                return [];
            }
            return response.data ?? [];
        },
    }),
    /**
     * Upsert employer admin rate
     * @param {EmployerAdminRateDTO} request
     */
    upsertEmployerAdminRate: builder.mutation({
        query: (request) => ({
            url: '/api/InternalRates/UpsertEmployerAdminRate',
            method: HttpMethod.Post,
            data: request
        }),
    }),
    /**
     * Fetch agency rates by email and plan ID
     * @param {GetAgencyRatesRequest} request
     * @returns {AgencyRateDTO[]}
     */
    getAgencyRates: builder.query({
        query: (request) => ({
            url: `/api/InternalRates/Get/AgencyRates?email=${encodeURIComponent(request.email)}&planId=${request.planId}`,
            method: HttpMethod.Get
        }),
        transformResponse: (response) => {
            if (!(response?.data instanceof Array)) {
                return [];
            }
            return response.data ?? [];
        },
    }),
    /**
     * Upsert agency rate
     * @param {AgencyRateDTO} request
     */
    upsertAgencyRate: builder.mutation({
        query: (request) => ({
            url: '/api/InternalRates/UpsertAgencyRate',
            method: HttpMethod.Post,
            data: request
        }),
    }),
    /**
     * Fetch broker rates by email and plan ID
     * @param {GetBrokerRatesRequest} request
     * @returns {BrokerRateDTO[]}
     */
    getBrokerRatesByPlanId: builder.query({
        query: (request) => ({
            url: `/api/InternalRates/Get/BrokerRates?email=${encodeURIComponent(request.email)}&planId=${request.planId}`,
            method: HttpMethod.Get
        }),
        transformResponse: (response) => {
            if (!(response?.data instanceof Array)) {
                return [];
            }
            return response.data ?? [];
        },
    }),
    /**
     * Upsert broker rate
     * @param {BrokerRateDTO} request
     */
    upsertBrokerRate: builder.mutation({
        query: (request) => ({
            url: '/api/InternalRates/UpsertBrokerRate',
            method: HttpMethod.Post,
            data: request
        }),
    }),
    /**
     * Fetch carrier rates by plan ID
     * @param {GetCarrierRatesRequest} request
     * @returns {CarrierRateDTO[]}
     */
    getCarrierRatesByPlanId: builder.query({
        query: (request) => ({
            url: `/api/InternalRates/Get/CarrierRates?planId=${request.planId}`,
            method: HttpMethod.Get
        }),
        transformResponse: (response) => {
            if (!(response?.data instanceof Array)) {
                return [];
            }
            return response.data ?? [];
        },
    }),
    /**
     * Upsert carrier rate
     * @param {CarrierRateDTO} request
     */
    upsertCarrierRate: builder.mutation({
        query: (request) => ({
            url: '/api/InternalRates/UpsertCarrierRate',
            method: HttpMethod.Post,
            data: request
        }),
    }),
});
