import React, { useEffect } from 'react';
import { Box, FormControl, FormLabel, FormGroup, FormControlLabel, Checkbox, Typography } from '@mui/material';
import useCustomTranslation from "../../hooks/useCustomTranslation";

const DependentCoverage = ({ planId, dependentsData, dependentsCoverage, onDependentChange }) => {
    const { translate } = useCustomTranslation();

    useEffect(() => {
    }, [planId, dependentsData, dependentsCoverage]);

    const handleDependentChange = (dependent) => {
        const isCurrentlyCovered = dependentsCoverage[dependent.dependentId] || false;
        onDependentChange(planId, dependent, !isCurrentlyCovered);
    };

    return (
        <Box sx={{ mt: 2 }}>
            <FormControl component="fieldset">
                <FormLabel component="legend">{translate('Select Dependents Covered')}</FormLabel>
                <Typography variant="body2" sx={{ mb: 2, color: 'blue' }}>
                    {translate('Please select the dependents covered by this plan')}
                </Typography>
                <FormGroup>
                    {dependentsData.map(dependent => {
                        const isCovered = dependentsCoverage[dependent.dependentId] || false;
                        return (
                            <FormControlLabel
                                key={dependent.dependentId}
                                control={
                                    <Checkbox
                                        checked={isCovered}
                                        onChange={() => handleDependentChange(dependent)}
                                        name={dependent.person.firstName}
                                    />
                                }
                                label={`${dependent.person.firstName} ${dependent.person.lastName}`}
                            />
                        );
                    })}
                </FormGroup>
            </FormControl>
        </Box>
    );
};

export default DependentCoverage;