import React, { useState, useEffect } from 'react';
import {
    Box,
    Typography,
    Paper,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Alert,
    Button,
    TextField,
    useTheme,
    useMediaQuery
} from '@mui/material';
import { ExpandMore } from '@mui/icons-material';
import useCustomTranslation from '../../hooks/useCustomTranslation';
import { useUpdateEmployerMutation, useGetEmployerByCodeQuery } from '../../reducers/enrollmentApiSlice';
import LoadingSpinner from "../LoadingSpinner";

const EmployerDetails = ({ selectedEmployer, userEmail }) => {
    const { translate } = useCustomTranslation();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [employerName, setEmployerName] = useState('');
    const [companyStatus, setCompanyStatus] = useState('');
    const [employerEIN, setEmployerEIN] = useState('');
    const [expanded, setExpanded] = useState(false);
    const [updateEmployer, { isLoading: isUpdating, isError: isUpdateError, error: updateError }] = useUpdateEmployerMutation();

    const { data: employerData, isLoading: isEmployerDataLoading, isError: isEmployerDataError, error: employerDataError } = useGetEmployerByCodeQuery(
        { employerCode: selectedEmployer?.employerCode, email: userEmail },
        { skip: !selectedEmployer?.employerCode || !userEmail }
    );

    useEffect(() => {
        if (employerData) {
            const { employerName, companyStatus, employerEIN } = employerData;
            setEmployerName(employerName);
            setCompanyStatus(companyStatus);
            setEmployerEIN(employerEIN);
        }
    }, [employerData]);

    const handleSaveChanges = async () => {
        try {
            await updateEmployer({
                employerCode: selectedEmployer.employerCode,
                employerName,
                companyStatus
            }).unwrap();
            // Show success message
            alert(translate('Changes saved successfully'));
        } catch (err) {
            console.error('Failed to update employer info:', err);
        }
    };

    const handleAccordionChange = (event, isExpanded) => {
        setExpanded(isExpanded);
    };

    return (
        <Paper sx={{ p: 2, mb: 4 }}>
            <Accordion expanded={expanded} onChange={handleAccordionChange} sx={{ mt: 2 }}>
                <AccordionSummary
                    expandIcon={<ExpandMore />}
                    aria-controls="employer-details-content"
                    id="employer-details-header"
                >
                    <Typography variant={isMobile ? "subtitle1" : "h6"}>
                        {translate('Employer Details')}
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <form onSubmit={(e) => { e.preventDefault(); handleSaveChanges(); }}>
                        <TextField
                            fullWidth
                            label={translate('Employer Code')}
                            value={selectedEmployer.employerCode}
                            InputProps={{
                                readOnly: true,
                            }}
                            sx={{ mb: 2 }}
                            disabled
                        />
                        <TextField
                            fullWidth
                            label={translate('Employer Name')}
                            value={employerName}
                            onChange={(e) => setEmployerName(e.target.value)}
                            sx={{ mb: 2 }}
                            required
                        />
                        <TextField
                            fullWidth
                            label={translate('Company Status')}
                            value={companyStatus}
                            onChange={(e) => setCompanyStatus(e.target.value)}
                            sx={{ mb: 2 }}
                            required
                        />
                        <TextField
                            fullWidth
                            label={translate('Employer EIN')}
                            value={employerEIN}
                            InputProps={{
                                readOnly: true,
                            }}
                            sx={{ mb: 2 }}
                            disabled
                        />
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            sx={{ mt: 2 }}
                            disabled={isUpdating}
                        >
                            {isUpdating ? translate('Saving...') : translate('Save Changes')}
                        </Button>
                    </form>
                </AccordionDetails>
            </Accordion>

            {isEmployerDataLoading && (
                <Box display="flex" justifyContent="center" mt={4}>
                    <LoadingSpinner />
                </Box>
            )}
            {isEmployerDataError && (
                <Alert severity="error" sx={{ mt: 2 }}>
                    {translate('Error loading employer data')}: {employerDataError.message}
                </Alert>
            )}
            {isUpdateError && (
                <Alert severity="error" sx={{ mt: 2 }}>
                    {translate('Error updating employer')}: {updateError.message}
                </Alert>
            )}
        </Paper>
    );
};

export default EmployerDetails;