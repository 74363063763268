import React, { useState, useEffect } from 'react';
import {
    Container, Box, Typography, Grid, Paper, CircularProgress, Alert, Accordion,
    AccordionSummary, AccordionDetails, Button, useTheme, useMediaQuery
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import EmployerCheckboxList from '../Employer/EmployerCheckboxList';
import useCustomTranslation from '../../hooks/useCustomTranslation';
import useGetEmployerCode from '../../hooks/useGetEmployerCode';
import AuthTokenService from "../../services/AuthTokenService";
import Branding from '../Branding';
import EmployerDetails from './EmployerDetails';
import EmployerLogoImageByEmployerCode from "./EmployerLogoImageByEmployerCode";
import OpenEnrollmentDatePicker from "./OpenEnrollmentDatePicker";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Claim } from "../../types/claim";
import { Policy } from "../../types/policy";
import logoImage from "../../assets/logo/default_logo.png";
import {
    useGetCarrierPlansByCarrierIdQuery,
    useGetAllEmployerPlanSelectionsQuery,
    useInsertEmployerPlanSelectionsMutation,
    useGetEmployerPlanOptionQuery
} from "../../reducers/enrollmentApiSlice";
import ActiveCarrierDropdown from "../Carrier/ActiveCarrierDropdown";
import PlansCarousel from "../Plan/PlansCarousel";
import {Modal, StyledBackdrop} from "../Modal/styled";
import ModalContent from "../Modal/ModalContent";
import {useNavigate} from "react-router-dom";

const EditEmployerInfo = () => {
    const { translate } = useCustomTranslation();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [selectedEmployer, setSelectedEmployer] = useState(null);
    const [selectedCarrier, setSelectedCarrier] = useState('');
    const [planSelections, setPlanSelections] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [accordionExpanded, setAccordionExpanded] = useState(false);
    const [isCarrierLoading, setIsCarrierLoading] = useState(false);
    const { user } = AuthTokenService.getAuthInfo();
    const userEmail = typeof user === 'string' ? user : user.email;

    const isGlobalAdmin = Policy.userHasSufficientPermissions(Policy.GlobalAdmin);
    const isEmployerAdmin = Policy.userHasSufficientPermissions([Claim.EmployerAdmin]);
    const isAuthorized = isGlobalAdmin || isEmployerAdmin;
    const navigate = useNavigate();

    const { employerCode, isEmployerCodeLoading, isError: isEmployerCodeError, error: employerCodeError } = useGetEmployerCode(
        { userEmail, shouldRedirect: false },
        { skip: selectedEmployer && isGlobalAdmin }
    );

    const { data: plans = [], error: plansError, isLoading: plansLoading } = useGetCarrierPlansByCarrierIdQuery(selectedCarrier, {
        skip: !selectedCarrier,
    });

    const { data: employerPlanOptions = [], isLoading: employerPlanOptionsLoading, isError: employerPlanOptionsError } = useGetEmployerPlanOptionQuery(
        { employerCode: selectedEmployer?.employerCode || employerCode },
        { skip: !employerCode && !selectedEmployer?.employerCode }
    );

    const { data: selectedPlans = [], isLoading: selectedPlansLoading } = useGetAllEmployerPlanSelectionsQuery(
        { employerCode: selectedEmployer?.employerCode || employerCode },
        { skip: !employerCode && !selectedEmployer?.employerCode }
    );

    const [insertEmployerPlanSelections] = useInsertEmployerPlanSelectionsMutation();

    useEffect(() => {
        if (employerCodeError) {
            console.error('Employer code error:', employerCodeError);
        } else if (employerCode && !selectedEmployer && isEmployerAdmin) {
            setSelectedEmployer({ employerCode, employerName: user.email });
        }
    }, [employerCodeError, employerCode, user.email, isEmployerAdmin]);

    useEffect(() => {
        if (selectedPlans.length > 0) {
            setPlanSelections(selectedPlans);
        }
    }, [selectedPlans]);

    useEffect(() => {
        if (!employerPlanOptionsLoading && (employerPlanOptionsError || employerPlanOptions.length === 0)) {
            setIsModalOpen(true);
        }
    }, [employerPlanOptionsLoading, employerPlanOptionsError, employerPlanOptions]);

    useEffect(() => {
        setIsCarrierLoading(!!selectedCarrier);
    }, [selectedCarrier]);

    useEffect(() => {
        if (!plansLoading && selectedCarrier) {
            setIsCarrierLoading(false);
        }
    }, [plansLoading, selectedCarrier]);

    const handleEmployerSelectionChange = (employers) => {
        if (employers.length > 0) {
            setSelectedEmployer(employers[0]);
        } else {
            setSelectedEmployer(null);
        }
    };

    const handleCarrierSelectionChange = (carrierId) => {
        setSelectedCarrier(carrierId);
    };

    const handlePlanSelectionChange = async (plan) => {
        const updatedSelections = planSelections.map(p =>
            p.planId === plan.planId ? { ...p, isActive: !p.isActive } : p
        );

        if (!updatedSelections.some(p => p.planId === plan.planId)) {
            updatedSelections.push({ ...plan, isActive: true });
        }

        const changes = updatedSelections.filter(updatedPlan => {
            const originalPlan = planSelections.find(p => p.planId === updatedPlan.planId);
            return !originalPlan || originalPlan.isActive !== updatedPlan.isActive;
        });

        if (changes.length === 0) return;

        try {
            await insertEmployerPlanSelections({
                adminEmail: userEmail,
                employerCode: selectedEmployer.employerCode,
                planSelections: changes.map(plan => ({
                    planId: plan.planId,
                    planName: plan.productName,
                    carrierRateIds: [],
                    agencyRateIds: [],
                    brokerRateIds: [],
                    isActive: plan.isActive,
                })),
            }).unwrap();
            setPlanSelections(updatedSelections);
        } catch (error) {
            console.error('Failed to update plan selection:', error);
        }
    };

    const handleOpenEnrollmentDatesChange = (dates) => {
        // Handle open enrollment dates change logic here
    };

    const handleCloseModal = () => setIsModalOpen(false);

    const handleNavigateToAssociation = () => navigate("/employer-plan-association");

    const handleAccordionChange = (event, isExpanded) => setAccordionExpanded(isExpanded);

    const filteredPlans = plans.filter(plan =>
        employerPlanOptions.some(option => option.planId === plan.planId)
    );

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Container component="main" maxWidth="lg">
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mb: 4, p: isMobile ? 2 : 6 }}>
                    <Branding logoUrl={logoImage} width={isMobile ? "150px" : "250px"} height={isMobile ? "45px" : "75px"} />
                    <Typography variant={isMobile ? "h5" : "h4"} gutterBottom>
                        {translate('Edit Employer Info')}
                    </Typography>
                </Box>

                <Box sx={{ my: 4 }}>
                    {isGlobalAdmin && (
                        <Paper sx={{ p: 2, mb: 4 }}>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <Typography variant="body1" sx={{ mt: 2 }}>
                                        {translate('Please select an employer to edit their information.')}
                                    </Typography>
                                    <EmployerCheckboxList onEmployerSelect={handleEmployerSelectionChange} />
                                </Grid>
                            </Grid>
                        </Paper>
                    )}

                    {isAuthorized && selectedEmployer && (
                        <>
                            <EmployerDetails selectedEmployer={selectedEmployer} userEmail={userEmail} />

                            <Paper sx={{ p: 2, mt: 4 }}>
                                <Typography variant="h6" gutterBottom>
                                    {translate('Employer Logo')}
                                </Typography>
                                <EmployerLogoImageByEmployerCode employerCode={selectedEmployer.employerCode} />
                            </Paper>

                            <Paper sx={{ p: 2, mt: 4 }}>
                                <OpenEnrollmentDatePicker
                                    employerCode={selectedEmployer.employerCode}
                                    startDate={null}
                                    endDate={null}
                                    onDatesChange={handleOpenEnrollmentDatesChange}
                                />
                            </Paper>

                            {(plansLoading || employerPlanOptionsLoading || selectedPlansLoading) && (
                                <Box display="flex" justifyContent="center" mt={4}>
                                    <CircularProgress />
                                </Box>
                            )}

                            {!plansLoading && !employerPlanOptionsLoading && !selectedPlansLoading && (
                                employerPlanOptionsError || employerPlanOptions.length === 0 ? (
                                    <Modal open={isModalOpen} onClose={handleCloseModal} BackdropComponent={StyledBackdrop}>
                                        <ModalContent>
                                            <Typography className="modal-title" variant="h6">
                                                {translate('No Plans Available')}
                                            </Typography>
                                            <Typography className="modal-description">
                                                {translate('There are no plans assigned to this company.')}
                                            </Typography>
                                            {isGlobalAdmin && (
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={handleNavigateToAssociation}
                                                    sx={{ mt: 2 }}
                                                >
                                                    {translate('Manage Plan Association')}
                                                </Button>
                                            )}
                                            <Button
                                                variant="outlined"
                                                onClick={handleCloseModal}
                                                sx={{ mt: 2 }}
                                            >
                                                {translate('Close')}
                                            </Button>
                                        </ModalContent>
                                    </Modal>
                                ) : (
                                    <Accordion
                                        expanded={accordionExpanded}
                                        onChange={handleAccordionChange}
                                        sx={{ mt: 4 }}
                                    >
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="choose-plan-content"
                                            id="choose-plan-header"
                                        >
                                            <Typography variant="h6">{translate('Choose Plans')}</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Paper sx={{ p: 2, mt: 2, mb: 2 }}>
                                                <Typography variant="h6" gutterBottom>
                                                    {translate('Select a Carrier')}
                                                </Typography>
                                                <ActiveCarrierDropdown
                                                    employerCode={selectedEmployer.employerCode}
                                                    onSelectionChange={handleCarrierSelectionChange}
                                                    selectedCarrierId={selectedCarrier}
                                                />
                                            </Paper>

                                            {selectedCarrier && (
                                                isCarrierLoading ? (
                                                    <Box display="flex" justifyContent="center" mt={4}>
                                                        <CircularProgress />
                                                    </Box>
                                                ) : (
                                                    <>
                                                        {plansError && <Alert severity="error">{translate('Failed to load plans')}</Alert>}
                                                        {!plansLoading && !plansError && filteredPlans.length === 0 && (
                                                            <Alert severity="info">{translate('No plans available for this carrier')}</Alert>
                                                        )}
                                                        {filteredPlans.length > 0 && (
                                                            <PlansCarousel
                                                                plans={filteredPlans}
                                                                selectedPlans={planSelections}
                                                                onPlanSelect={handlePlanSelectionChange}
                                                                employerCode={selectedEmployer.employerCode}
                                                            />
                                                        )}
                                                    </>
                                                )
                                            )}
                                        </AccordionDetails>
                                    </Accordion>
                                )
                            )}
                        </>
                    )}

                    {isEmployerCodeLoading && (
                        <Box display="flex" justifyContent="center" mt={4}>
                            <CircularProgress />
                        </Box>
                    )}
                    {isEmployerCodeError && <Alert severity="error">{employerCodeError}</Alert>}
                </Box>
            </Container>
        </LocalizationProvider>
    );
};

export default EditEmployerInfo;