import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import {
    useGetEmployerAdminRatesByPlanIdQuery,
    useGetAllContributionTypesQuery,
    useGetBenefitTypesQuery,
    useGetAllRateTypesQuery,
    useGetAllIndividualTypesQuery
} from '../../reducers/enrollmentApiSlice';
import {
    Box,
    Typography,
    Alert,
    Paper,
    ToggleButtonGroup,
    ToggleButton,
    useMediaQuery,
    useTheme,
    List,
    ListItem,
    ListItemText,
    Divider,
    TextField,
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import LoadingSpinner from "../LoadingSpinner";
import NoRatesOverlay from './NoRatesOverlay';
import AuthTokenService from "../../services/AuthTokenService";
import { getEmployerAdminRateColumns, getActiveFieldsForRateType } from './EmployerAdminRates/employerAdminRateColumns';

const ReadOnlyEmployerAdminRatesTable = ({ plan, employerCode }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const email = AuthTokenService.getAuthInfo().user || '';

    const [rateType, setRateType] = useState('all');
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedContributionType, setSelectedContributionType] = useState('');
    const [benefitType, setBenefitType] = useState('');

    const { data, error, isLoading } = useGetEmployerAdminRatesByPlanIdQuery({
        planId: plan.planId,
        email: email,
        employerCode: employerCode
    });

    const { data: contributionTypesData, error: contributionTypesError, isLoading: contributionTypesLoading } = useGetAllContributionTypesQuery();
    const { data: benefitTypesData, error: benefitTypesError, isLoading: benefitTypesLoading } = useGetBenefitTypesQuery();
    const { data: rateTypesData, error: rateTypesError, isLoading: rateTypesLoading } = useGetAllRateTypesQuery();
    const { data: individualTypesData, error: individualTypesError, isLoading: individualTypesLoading } = useGetAllIndividualTypesQuery();

    useEffect(() => {
        if (benefitTypesData && plan.benefitTypeId) {
            const matchedBenefitType = benefitTypesData.find(bt => bt.benefitTypeId === plan.benefitTypeId);
            if (matchedBenefitType) {
                setBenefitType(matchedBenefitType.typeName);
            }
        }
    }, [benefitTypesData, plan.benefitTypeId]);

    useEffect(() => {
        if (data && data.length > 0) {
            setSelectedContributionType(data[0].contributionTypeId.toString());
            setRateType(data[0].rateTypeName.toLowerCase());
        }
    }, [data]);

    const formattedRates = useMemo(() => {
        if (data && data.length > 0 && contributionTypesData) {
            return data.map(rate => {
                const contributionType = contributionTypesData.find(
                    (type) => type.contributionTypeId === rate.contributionTypeId
                );

                return {
                    ...rate,
                    effectiveDate: rate.effectiveDate ? new Date(rate.effectiveDate).toISOString().split('T')[0] : '',
                    expirationDate: rate.expirationDate ? new Date(rate.expirationDate).toISOString().split('T')[0] : '',
                    rateId: rate.rateId || `temp-${Math.random()}`,
                    rate: parseFloat(rate.rate),
                    smokingStatus: rate.smokingStatus ? 'Yes' : 'No',
                    contributionTypeName: contributionType ? contributionType.typeName : 'N/A',
                    contributionValueFormatted: rate.contributionValue !== null ? `$${rate.contributionValue.toFixed(2)}` : 'N/A',
                    isActive: rate.isActive ? 'Yes' : 'No',
                    eoiMax: rate.eoiMax !== null ? `$${rate.eoiMax.toFixed(2)}` : 'N/A',
                    max: rate.max !== null ? `$${rate.max.toFixed(2)}` : 'N/A',
                    increments: rate.increments !== null ? `$${rate.increments.toFixed(2)}` : 'N/A',
                };
            });
        }
        return [];
    }, [data, contributionTypesData]);

    const filteredRates = useMemo(() => {
        return formattedRates.filter(rate =>
            (rateType === 'all' || rate.rateTypeName.toLowerCase() === rateType.toLowerCase()) &&
            (searchTerm === '' || Object.values(rate).some(val =>
                val && val.toString().toLowerCase().includes(searchTerm.toLowerCase())
            ))
        );
    }, [formattedRates, rateType, searchTerm]);

    const handleRateTypeChange = (event, newRateType) => {
        if (newRateType !== null) {
            setRateType(newRateType);
        }
    };

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    };

    const columns = useMemo(() => {
        if (!rateTypesData || !benefitType || !individualTypesData) return [];

        const allColumns = getEmployerAdminRateColumns(
            () => {}, // No-op function for read-only table
            rateTypesData,
            individualTypesData,
            filteredRates,
            {},
            selectedContributionType
        );

        const contributionType = contributionTypesData?.find(
            type => type.contributionTypeId === parseInt(selectedContributionType, 10)
        )?.typeName || '';

        const activeFields = getActiveFieldsForRateType(rateType, benefitType, contributionType);

        return allColumns.filter(column => activeFields.includes(column.field)).map(column => ({
            ...column,
            editable: false,
            renderCell: (params) => {
                if (column.field === 'coverageTier') {
                    return params.value || 'N/A';
                }
                if (column.type === 'boolean') {
                    return params.value ? 'Yes' : 'No';
                }
                return params.value;
            }
        }));
    }, [rateTypesData, benefitType, individualTypesData, rateType, selectedContributionType, filteredRates, contributionTypesData]);

    if (isLoading || contributionTypesLoading || benefitTypesLoading || rateTypesLoading || individualTypesLoading) {
        return <LoadingSpinner />;
    }

    if (error || contributionTypesError || benefitTypesError || rateTypesError || individualTypesError) {
        console.error("Failed to load data:", error || contributionTypesError || benefitTypesError || rateTypesError || individualTypesError);
        return <Alert severity="error">Failed to load rates. Please try again later.</Alert>;
    }

    const MobileView = () => (
        <Box>
            <TextField
                fullWidth
                variant="outlined"
                placeholder="Search rates..."
                value={searchTerm}
                onChange={handleSearchChange}
                sx={{ mb: 2 }}
            />
            <ToggleButtonGroup
                value={rateType}
                exclusive
                onChange={handleRateTypeChange}
                aria-label="Rate Type"
                orientation="vertical"
                fullWidth
                sx={{ mb: 2 }}
            >
                <ToggleButton value="all" aria-label="All Rates">All Rates</ToggleButton>
                <ToggleButton value="age banded" aria-label="Age Banded Rates">Age Banded</ToggleButton>
                <ToggleButton value="composite" aria-label="Composite Rates">Composite</ToggleButton>
            </ToggleButtonGroup>
            <List>
                {filteredRates.map((rate, index) => (
                    <React.Fragment key={rate.rateId}>
                        <ListItem alignItems="flex-start">
                            <ListItemText
                                primary={`${rate.rateTypeName} - $${parseFloat(rate.rate).toFixed(2)}`}
                                secondary={
                                    <React.Fragment>
                                        {columns.map(column => (
                                            <Typography key={column.field} component="span" variant="body2" color="text.primary">
                                                {`${column.headerName}: ${rate[column.field] || 'N/A'}`}
                                                <br />
                                            </Typography>
                                        ))}
                                    </React.Fragment>
                                }
                            />
                        </ListItem>
                        {index < filteredRates.length - 1 && <Divider />}
                    </React.Fragment>
                ))}
            </List>
        </Box>
    );

    const DesktopView = () => (
        <Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
                <ToggleButtonGroup
                    value={rateType}
                    exclusive
                    onChange={handleRateTypeChange}
                    aria-label="Rate Type"
                >
                    <ToggleButton value="all" aria-label="All Rates">All Rates</ToggleButton>
                    <ToggleButton value="age banded" aria-label="Age Banded Rates">Age Banded</ToggleButton>
                    <ToggleButton value="composite" aria-label="Composite Rates">Composite</ToggleButton>
                </ToggleButtonGroup>
                <TextField
                    variant="outlined"
                    placeholder="Search rates..."
                    value={searchTerm}
                    onChange={handleSearchChange}
                    size="small"
                />
            </Box>
            <Box sx={{ height: 600, width: '100%' }}>
                {filteredRates.length > 0 ? (
                    <DataGrid
                        rows={filteredRates}
                        columns={columns}
                        getRowId={(row) => row.rateId}
                        disableSelectionOnClick
                        components={{
                            NoRowsOverlay: NoRatesOverlay,
                        }}
                    />
                ) : (
                    <Alert severity="info">No rates available for this selection.</Alert>
                )}
            </Box>
        </Box>
    );

    return (
        <Paper sx={{ p: 2, width: '100%', overflow: 'hidden' }}>
            <Typography variant="h6" gutterBottom>
                Benefit Type: {benefitType}
            </Typography>
            {isMobile ? <MobileView /> : <DesktopView />}
        </Paper>
    );
};

ReadOnlyEmployerAdminRatesTable.propTypes = {
    plan: PropTypes.shape({
        planId: PropTypes.number.isRequired,
        benefitTypeId: PropTypes.number.isRequired,
    }).isRequired,
    employerCode: PropTypes.string.isRequired,
};

export default ReadOnlyEmployerAdminRatesTable;