import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Box, Container, Typography, Link, Grid, IconButton, useTheme } from '@mui/material';
import Branding from './Branding';
import logoImageWhite from '../assets/logo/default_logo_white.png';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import useCustomTranslation from "../hooks/useCustomTranslation";

const Footer = () => {
    const theme = useTheme();
    const logoUrl = logoImageWhite;
    const { translate } = useCustomTranslation();

    const footerLinks = [
        { to: "/about-us", label: translate('About us') },
        { to: "/contact-us", label: translate('Contact') },
    ];

    const socialLinks = [
        { href: "https://www.linkedin.com/company/gowell-benefits/posts/?feedView=all", icon: LinkedInIcon, label: "LinkedIn" },
        { href: "https://www.facebook.com/people/Gowell-Benefits/pfbid02QVMUWHxr56NzjBbKTKfH8bmEqv8jgmDZ1ebSxYJkkJaTWN78YWtuDyQf8UzSA27Sl/?mibextid=LQQJ4d", icon: FacebookIcon, label: "Facebook" },
        { href: "https://www.instagram.com/gowell_benefits/", icon: InstagramIcon, label: "Instagram" },
    ];

    return (
        <Box
            component="footer"
            sx={{
                backgroundColor: theme.palette.primary.main,
                color: theme.palette.primary.contrastText,
                py: { xs: 4, md: 6 },
                pb: 0,
            }}
        >
            <Container maxWidth="lg">
                <Grid container spacing={3} justifyContent="center" alignItems="center">
                    {footerLinks.map((link, index) => (
                        <Grid item key={index} xs={12} sm={6} md={4} lg={2}>
                            <Typography variant="h6" component="h2" fontWeight="bold" textAlign="center">
                                <RouterLink
                                    to={link.to}
                                    style={{ color: theme.palette.primary.contrastText, textDecoration: 'none' }}
                                >
                                    {link.label}
                                </RouterLink>
                            </Typography>
                        </Grid>
                    ))}
                </Grid>

                <Box sx={{ my: { xs: 3, md: 5 } }}>
                    <hr style={{ borderColor: theme.palette.primary.contrastText, margin: 0 }} />
                </Box>

                <Box textAlign="center" my={{ xs: 3, md: 5 }}>
                    <Typography variant="body1">
                        {translate('GoWell Benefits is committed to providing comprehensive and innovative benefits solutions to businesses and individuals. Our mission is to enhance well-being and security through tailored benefit packages.')}
                    </Typography>
                </Box>

                <Box display="flex" justifyContent="center" alignItems="center" my={{ xs: 3, md: 5 }}>
                    <Branding width="auto" logoUrl={logoUrl} alt="GoWell Benefits Logo" />
                </Box>

                <Box textAlign="center" my={{ xs: 3, md: 5 }}>
                    {socialLinks.map((social, index) => (
                        <IconButton
                            key={index}
                            component="a"
                            href={social.href}
                            target="_blank"
                            rel="noopener noreferrer"
                            aria-label={social.label}
                            sx={{
                                mx: 1,
                                color: theme.palette.primary.contrastText,
                                '&:hover': {
                                    color: theme.palette.primary.light,
                                    backgroundColor: 'rgba(255, 255, 255, 0.1)'
                                }
                            }}
                        >
                            <social.icon />
                        </IconButton>
                    ))}
                </Box>
            </Container>
            <Box
                textAlign="center"
                py={1.5}
                width="100%"
                sx={{
                    backgroundColor: theme.palette.primary.dark,
                }}
            >
                <Typography variant="body2">
                    © {new Date().getFullYear()} {translate('Copyright:')}
                    <Link
                        href="https://gowellbenefits.com/"
                        color="inherit"
                        underline="hover"
                        target="_blank"
                        rel="noopener noreferrer"
                        sx={{ ml: 1 }}
                    >
                        {translate('GoWell Benefits, LLC')}
                    </Link>
                </Typography>
            </Box>
        </Box>
    );
};

export default Footer;