import React from 'react';
import PropTypes from 'prop-types';
import { TextField, Switch, FormControlLabel } from '@mui/material';
import useCustomTranslation from "../../hooks/useCustomTranslation";

const FormFields = ({ formData, setFormData, additionalFields, sharedInfo, setSharedInfo }) => {
    const { translate } = useCustomTranslation();

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    return (
        <div>
            {additionalFields.map((field) => (
                <TextField
                    key={field.name}
                    label={translate(field.label)}
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    name={field.name}
                    value={formData[field.name] || ''}
                    onChange={handleInputChange}
                />
            ))}
            <TextField
                label={translate('Description')}
                variant="outlined"
                fullWidth
                multiline
                rows={4}
                margin="normal"
                name="description"
                value={formData.description || ''}
                onChange={handleInputChange}
            />
            {sharedInfo !== undefined && (
                <FormControlLabel
                    control={<Switch checked={sharedInfo} onChange={() => setSharedInfo(!sharedInfo)} />}
                    label={translate('Same Info for All')}
                />
            )}
        </div>
    );
};

FormFields.propTypes = {
    formData: PropTypes.object.isRequired,
    setFormData: PropTypes.func.isRequired,
    additionalFields: PropTypes.array.isRequired,
    sharedInfo: PropTypes.bool,
    setSharedInfo: PropTypes.func
};

export default FormFields;