import { CircularProgress, TextField, Autocomplete } from "@mui/material";
import React from "react";
import useCustomTranslation from "../hooks/useCustomTranslation";
import { debounce } from '@mui/material/utils';
import { useLazyGetAutocompleteResultsQuery } from "../reducers/enrollmentApiSlice";
import authTokenService from "../services/AuthTokenService";
import { logInfo, logError, logDebug } from '../utilities/Logger';

function AddressAutoComplete({ onChange, id = '' }) {
    const [value, setValue] = React.useState(null);
    const [inputValue, setInputValue] = React.useState('');
    const [open, setOpen] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [options, setOptions] = React.useState([]);
    const [addressDetails, setAddressDetails] = React.useState({
        addressLine1: '',
        addressLine2: '',
        city: '',
        region: '',
        country: '',
        postalCode: ''
    });

    const [getAutocompleteResultsTrigger] = useLazyGetAutocompleteResultsQuery();
    const { translate } = useCustomTranslation();

    const getAddress = React.useMemo(
        () =>
            debounce((fn) => {
                fn();
            }, 1000),
        [],
    );

    React.useEffect(() => {
        if (inputValue.length >= 5 && open) {
            setLoading(true);
            getAddress(async () => {
                try {
                    const items = await getAutocompleteResultsTrigger({
                        email: authTokenService.getAuthInfo().user,
                        query: inputValue
                    });
                    setOptions([...(items?.data ?? [])]);
                } catch (error) {
                    logError(`Failed to fetch autocomplete results: ${error}`);
                } finally {
                    setLoading(false);
                }
            });
        }
    }, [inputValue, open, getAddress, getAutocompleteResultsTrigger]);

    const handleInputChange = (event, newInputValue) => {
        if (newInputValue.length >= 5) {
            setInputValue(newInputValue);
        } else {
            setInputValue('');
            setOptions([]);
        }
    };

    const handleOptionChange = (event, value, reason) => {
        setValue(value);

        if (reason !== 'clear') {
            const item = value;

            const updatedAddressDetails = {
                addressLine1: item?.address?.street ?? '',
                addressLine2: item?.address?.addressLine2 ?? '',
                city: item?.address?.city ?? '',
                region: item?.address?.state ?? '',
                country: item?.address?.countryName ?? '',
                postalCode: item?.address?.postalCode ?? ''
            };

            setAddressDetails(updatedAddressDetails);
            onChange(updatedAddressDetails);
        }
    };

    const handleFieldChange = (field) => (event) => {
        const newValue = event.target.value;
        setAddressDetails((prevDetails) => {
            const updatedDetails = { ...prevDetails, [field]: newValue };
            onChange(updatedDetails);
            return updatedDetails;
        });
        if (field === 'addressLine1') {
            setInputValue(newValue);
        }
    };

    return (
        <Autocomplete
            id={`address-auto-complete-${id}`}
            open={open}
            onOpen={() => {
                setOpen(true);
            }}
            onClose={() => {
                setOpen(false);
            }}
            freeSolo
            autoComplete
            includeInputInList
            value={value}
            noOptionsText={translate("No locations found...")}
            onChange={handleOptionChange}
            onInputChange={handleInputChange}
            getOptionLabel={(option) => option?.address?.label ?? option?.title ?? ''}
            options={options}
            filterOptions={(options) => options}
            loading={loading}
            renderOption={(props, option) => {
                const label = option?.address?.label ?? option?.title ?? '';
                return (
                    <li {...props} key={label}>
                        {label}
                    </li>
                );
            }}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={translate('Street')}
                    value={addressDetails.addressLine1}
                    onChange={handleFieldChange('addressLine1')}
                    fullWidth
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <React.Fragment>
                                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                            </React.Fragment>
                        ),
                    }}
                />
            )}
        />
    );
}

export default AddressAutoComplete;