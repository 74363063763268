import React from 'react';
import { Box, Grid, Paper, useTheme, Typography, Container } from '@mui/material';
import useCustomTranslation from '../../hooks/useCustomTranslation';
import PolicyBasedAccess from '../../components/PolicyBasedAccess';
import { Policy } from '../../types/policy';
import Branding from '../../components/Branding';
import logoImage from '../../assets/logo/default_logo.png';
import girlInYellowImage from '../../assets/images/GirlInYellow.png';
import DashboardLink from "../DashboardLink";
import { Settings, HealthAndSafety, SupervisorAccount, Business, Upload } from "@mui/icons-material";

const BrokerPortal = () => {
    const theme = useTheme();
    const { translate } = useCustomTranslation();

    return (
        <Container maxWidth="xl">
            <Box
                sx={{
                    position: 'relative',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: { xs: 3, sm: 4 },
                    py: { xs: 3, md: 5 },
                    minHeight: '100vh',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: '100%',
                    mx: 'auto',
                    '::before': {
                        content: '""',
                        backgroundImage: `url(${girlInYellowImage})`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        backgroundRepeat: 'no-repeat',
                        opacity: 0.3,
                        top: 0,
                        left: 0,
                        bottom: 0,
                        right: 0,
                        position: 'absolute',
                        zIndex: -1,
                    },
                }}
            >
                <Paper
                    elevation={3}
                    sx={{
                        backgroundColor: 'rgba(255, 255, 255, 0.3)',
                        padding: { xs: 2, sm: 3, md: 4 },
                        borderRadius: theme.shape.borderRadius,
                        width: '100%',
                    }}
                >
                    <Branding
                        logoUrl={logoImage}
                        width="200px"
                        height="60px"
                        style={{ marginBottom: theme.spacing(4) }}
                    />

                    <Typography variant="h4" component="h1" gutterBottom align="center">
                        {translate('Broker Portal')}
                    </Typography>

                    <Grid container spacing={3} justifyContent="center">
                        <PolicyBasedAccess policy={Policy.GlobalAdmin}>
                            <Grid item xs={12} sm={6} md={4}>
                                <DashboardLink
                                    title={'Choose Company Plans'}
                                    description={'Choose plans for your companies here.'}
                                    link={'/employer-plan-association'}
                                    icon={<HealthAndSafety />}
                                />
                            </Grid>
                        </PolicyBasedAccess>
                        <PolicyBasedAccess policy={Policy.GlobalAdmin}>
                            <Grid item xs={12} sm={6} md={4}>
                                <DashboardLink
                                    title={'Create New Company'}
                                    description={'Create a new company here.'}
                                    link={'/create-employer'}
                                    icon={<SupervisorAccount />}
                                />
                            </Grid>
                        </PolicyBasedAccess>
                        <PolicyBasedAccess policy={Policy.BrokerOrGlobalAdmin}>
                            <Grid item xs={12} sm={6} md={4}>
                                <DashboardLink
                                    title={'Build a Plan'}
                                    description={'Create a new plan here!'}
                                    link={'/build-plan'}
                                    icon={<Business />}
                                />
                            </Grid>
                        </PolicyBasedAccess>
                        <PolicyBasedAccess policy={Policy.GlobalAdmin}>
                            <Grid item xs={12} sm={6} md={4}>
                                <DashboardLink
                                    title={'Census Upload'}
                                    description={'Upload your Census here.'}
                                    link={'/admin/census-upload'}
                                    icon={<Upload />}
                                />
                            </Grid>
                        </PolicyBasedAccess>
                    </Grid>
                </Paper>
            </Box>
        </Container>
    );
};

export default BrokerPortal;