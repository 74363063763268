import { useState, useEffect, useCallback } from 'react';
import {
    useGetCarrierRatesByPlanIdQuery,
    useGetAllRateTypesQuery,
    useUpsertCarrierRateMutation,
    useGetBenefitTypesQuery,
    useGetAllIndividualTypesQuery,
} from '../reducers/enrollmentApiSlice';
import AuthTokenService from '../services/AuthTokenService';

const useCarrierRates = (planId, initialRateType, initialBenefitType) => {
    const [rates, setRates] = useState([]);
    const [rateType, setRateType] = useState(initialRateType);
    const [benefitType, setBenefitType] = useState(initialBenefitType);
    const [alert, setAlert] = useState(null);

    const {
        data: ratesData,
        error: ratesError,
        isLoading: ratesLoading,
        refetch
    } = useGetCarrierRatesByPlanIdQuery({ planId });

    const {
        data: rateTypesData,
        error: rateTypesError,
        isLoading: rateTypesLoading
    } = useGetAllRateTypesQuery();

    const {
        data: benefitTypesData,
        error: benefitTypesError,
        isLoading: benefitTypesLoading
    } = useGetBenefitTypesQuery();

    const {
        data: individualTypesData,
        error: individualTypesError,
        isLoading: individualTypesLoading
    } = useGetAllIndividualTypesQuery();

    const [upsertCarrierRate, { isLoading: isUpsertingRate }] = useUpsertCarrierRateMutation();

    useEffect(() => {
        if (ratesData && ratesData.length > 0) {
            const filteredData = ratesData.filter(rate =>
                rate.rateTypeName.toLowerCase() === rateType.toLowerCase() &&
                rate.benefitType.typeName === benefitType
            );
            const formattedData = filteredData.map(rate => ({
                ...rate,
                effectiveDate: rate.effectiveDate ? new Date(rate.effectiveDate).toISOString().split('T')[0] : '',
                expirationDate: rate.expirationDate ? new Date(rate.expirationDate).toISOString().split('T')[0] : '',
                rateId: rate.rateId || 0,
                benefitType: rate.benefitType || { benefitTypeId: 0, typeName: '' },
            }));
            setRates(formattedData);
        } else {
            setRates([]);
        }
    }, [ratesData, rateType, benefitType]);

    const handleAddRate = useCallback((planId, carrierId) => {
        if (!rateTypesData || !rateTypesData.length) {
            console.error('Rate types data is not available');
            return;
        }

        const rateTypeObj = rateTypesData.find(type => type.rateTypeName.toLowerCase() === rateType.toLowerCase());
        if (!rateTypeObj) {
            console.error('Matching rate type not found for:', rateType);
            return;
        }

        const benefitTypeObj = benefitTypesData?.find(type => type.typeName === benefitType);
        if (!benefitTypeObj) {
            console.error('Benefit type not found');
            return;
        }

        const newRate = {
            rateId: 0,
            carrierId,
            rate: 0,
            smokingStatus: false,
            effectiveDate: '',
            expirationDate: '',
            planId,
            coverageTier: '',
            rateTypeId: rateTypeObj.rateTypeId,
            rateTypeName: rateTypeObj.rateTypeName,
            customRateString: '',
            ageBand: '',
            email: AuthTokenService.getAuthInfo().user || '',
            isActive: true,
            benefitType: benefitTypeObj
        };

        setRates(prevRates => [...prevRates, newRate]);
    }, [rateTypesData, rateType, benefitType, benefitTypesData]);

    const handleInputChange = useCallback((id, field, value) => {
        setRates(prevRates => prevRates.map(rate =>
            rate.rateId === id ? { ...rate, [field]: value } : rate
        ));
    }, []);

    const handleCoverageTierChange = useCallback((id, value) => {
        setRates(prevRates => prevRates.map(rate =>
            rate.rateId === id ? { ...rate, coverageTier: value } : rate
        ));
    }, []);

    const handleSaveRates = useCallback(async () => {
        const { successCount, failureCount } = await processRates(rates);
        if (successCount > 0) {
            await refetch();  // Refetch rates after successful upsert
        }
        displayResultAlert(successCount, failureCount);
        resetAlertAfterDelay();
    }, [rates, upsertCarrierRate, refetch]);

    const processRates = async (rates) => {
        let successCount = 0;
        let failureCount = 0;

        for (const rate of rates) {
            const carrierRateDTO = mapRateToDTO(rate);

            try {
                await upsertCarrierRate(carrierRateDTO).unwrap();
                successCount++;
            } catch (error) {
                console.error('Upsert error:', error);
                failureCount++;
            }
        }

        return { successCount, failureCount };
    };

    const mapRateToDTO = (rate) => ({
        rateId: rate.rateId || 0,
        carrierId: rate.carrierId,
        rate: rate.rate || 0,
        smokingStatus: rate.smokingStatus === undefined ? null : rate.smokingStatus,
        effectiveDate: rate.effectiveDate || "",
        expirationDate: rate.expirationDate || null,
        planId: rate.planId,
        coverageTier: rate.coverageTier || "",
        rateTypeId: rate.rateTypeId,
        rateTypeName: rate.rateTypeName,
        customRateString: rate.customRateString || null,
        ageBand: rate.ageBand || null,
        email: AuthTokenService.getAuthInfo().user || '',
        isActive: rate.isActive,
        benefitType: rate.benefitType || { benefitTypeId: null, typeName: "" }
    });

    const displayResultAlert = (successCount, failureCount) => {
        if (successCount > 0 && failureCount === 0) {
            setAlert({ type: 'success', message: 'Rates upserted successfully!' });
        } else if (failureCount > 0) {
            setAlert({ type: 'error', message: `Failed to upsert ${failureCount} rate(s).` });
        }
    };

    const resetAlertAfterDelay = () => {
        setTimeout(() => {
            setAlert(null);
        }, 10000);
    };

    const changeRateType = useCallback((newRateType, newBenefitType) => {
        setRateType(newRateType);
        setBenefitType(newBenefitType);
    }, []);

    const clearUnsavedRates = useCallback(() => {
        setRates(prevRates => prevRates.filter(rate => rate.rateId !== 0));
    }, []);

    return {
        rates,
        rateType,
        benefitType,
        rateTypesData,
        benefitTypesData,
        individualTypesData,
        isLoading: ratesLoading || rateTypesLoading || benefitTypesLoading || individualTypesLoading,
        isUpsertingRate,
        error: ratesError || rateTypesError || benefitTypesError || individualTypesError,
        alert,
        handleInputChange,
        handleCoverageTierChange,
        handleAddRate,
        handleSaveRates,
        changeRateType,
        clearUnsavedRates,
        refetch,
    };
};

export default useCarrierRates;