import React, {useEffect, useState} from 'react';
import {
    Container,
    Box,
    Typography,
    Grid,
    Paper,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Link,
    Alert,
    useTheme,
    useMediaQuery
} from '@mui/material';
import { ExpandMore } from '@mui/icons-material';
import useCustomTranslation from '../../hooks/useCustomTranslation';
import CarrierDropdown from '../Carrier/CarrierDropdown';
import PlanDropdown from '../PlanDropdown';
import EditCarrierForm from '../Carrier/EditCarrierForm';
import EditPlanForm from "./EditPlanForm";
import EditableCarrierLogo from "../Carrier/EditableCarrierLogo";
import LoadingSpinner from '../LoadingSpinner';
import Branding from '../Branding';
import logoImage from "../../assets/logo/default_logo.png";
import CarrierRatesManager from '../RatesTable/CarrierRates/CarrierRatesManager';
import EditableCarrierPlanDocuments from "../Carrier/EditableCarrierPlanDocuments";
import CarrierQuestionsList from "../Carrier/CarrierQuestionsList";
import PlanQuestionsList from "../Plan/PlanQuestionsList";
import { useGetBenefitTypesQuery } from "../../reducers/enrollmentApiSlice";

const BuildPlansPage = () => {
const { data: benefitTypes, isLoading: isBenefitTypesLoading } = useGetBenefitTypesQuery();
const [selectedCarrier, setSelectedCarrier] = useState('');
const [selectedPlan, setSelectedPlan] = useState(null);
const [isLoading, setIsLoading] = useState(false);
const { translate } = useCustomTranslation();
const [error, setError] = useState(null);
const [plansAvailable, setPlansAvailable] = useState(false);
const [plansLoaded, setPlansLoaded] = useState(false);
const theme = useTheme();
const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

const handleCarrierSelectionChange = (carrierId) => {
    setSelectedCarrier(carrierId ?? '');
    setSelectedPlan(null);
    setError(null);
    setPlansLoaded(false);
};

const handlePlanSelectionChange = (plan) => {
    setSelectedPlan(plan);
    setError(null);
};

const handlePlansLoaded = (plans) => {
    setPlansAvailable(plans.length > 0);
    setPlansLoaded(true);
};

const getInitialRateType = (benefitTypeId) => {
    if (!benefitTypes || !benefitTypeId) return 'Age Banded';

    const benefitType = benefitTypes.find(bt => bt.benefitTypeId === benefitTypeId);
    if (['Life', 'STD', 'LTD', 'Voluntary Life', 'Basic Life', 'Permanent Life'].includes(benefitType?.typeName)) {
        return 'Age Banded';
    }
    return 'Composite';
};

useEffect(() => {
    if (selectedPlan && !selectedPlan.benefitTypeId) {
        console.error('Selected plan does not have a benefitTypeId');
        setError('Invalid plan data. Please try selecting the plan again.');
    }
}, [selectedPlan]);

if (isBenefitTypesLoading) {
    return <LoadingSpinner />;
}

    return (
        <Container component="main" maxWidth="lg">
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mb: 4, p: isMobile ? 2 : 6 }}>
                <Branding logoUrl={logoImage} width={isMobile ? "150px" : "250px"} height={isMobile ? "45px" : "75px"} />
            </Box>
            <Box sx={{ my: 4 }}>
                <Paper sx={{ p: 2, mb: 4 }}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Typography variant="body1" sx={{ mt: 2 }}>
                                {translate('Please select a carrier or')}
                                <Link href="/create-carrier" sx={{ ml: 1 }} aria-label={translate('Create a new carrier')}>
                                    {translate('create a new carrier')}
                                </Link>
                                .
                            </Typography>
                            <CarrierDropdown onSelectionChange={handleCarrierSelectionChange} />
                            {selectedCarrier && (
                                <Accordion sx={{ mt: 2 }}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMore />}
                                        aria-controls="edit-carrier-content"
                                        id="edit-carrier-header"
                                    >
                                        <Typography variant="h6">
                                            {translate('Edit Carrier Details')}
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <EditCarrierForm />
                                    </AccordionDetails>
                                </Accordion>
                            )}
                        </Grid>
                    </Grid>
                </Paper>

                {selectedCarrier && (
                    <Paper sx={{ p: 2, mb: 4 }}>
                        <Accordion sx={{ mt: 2 }}>
                            <AccordionSummary
                                expandIcon={<ExpandMore />}
                                aria-controls="manage-carrier-questions-content"
                                id="manage-carrier-questions-header"
                            >
                                <Typography variant="h6">
                                    {translate('Manage Carrier Questions')}
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <CarrierQuestionsList carrierId={selectedCarrier} />
                            </AccordionDetails>
                        </Accordion>
                    </Paper>
                )}

                {selectedCarrier && (
                    <Paper sx={{ p: 2, mb: 4 }}>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Typography variant="h6" sx={{ mb: 2 }}>
                                    {translate('Please choose a plan or create a new one.')}
                                </Typography>
                                <PlanDropdown
                                    carrierId={selectedCarrier}
                                    onSelectionChange={handlePlanSelectionChange}
                                    onPlansLoaded={handlePlansLoaded}
                                />
                                {plansLoaded && !plansAvailable && (
                                    <Typography sx={{ mt: 2, color: 'text.secondary' }}>
                                        {translate('No plans available for this carrier.')}
                                    </Typography>
                                )}
                                <Accordion sx={{ mt: 2 }}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMore />}
                                        aria-controls="edit-plan-content"
                                        id="edit-plan-header"
                                    >
                                        <Typography variant="h6">
                                            {selectedPlan
                                                ? translate('Edit Plan Details')
                                                : translate('Create a New Plan')}
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <EditPlanForm plan={selectedPlan} carrierId={selectedCarrier} />
                                    </AccordionDetails>
                                </Accordion>
                            </Grid>
                        </Grid>
                    </Paper>
                )}

                {selectedPlan && selectedPlan.benefitTypeId && benefitTypes && (
                    <Box sx={{ mt: 4 }}>
                        <Paper sx={{ p: 2 }}>
                            <Typography variant="h5" gutterBottom>
                                {translate('Edit Rates for the Selected Plan')}
                            </Typography>
                            <Typography variant="body1" sx={{ mb: 2 }}>
                                {translate('Use the table below to adjust the rates for the selected plan.')}
                            </Typography>
                            <CarrierRatesManager
                                plan={selectedPlan}
                                initialRateType={getInitialRateType(selectedPlan.benefitTypeId)}
                            />
                        </Paper>
                    </Box>
                )}




                {isLoading && <LoadingSpinner />}
                {error && (
                    <Alert severity="error" role="alert" sx={{ mt: 2 }}>
                        {error}
                    </Alert>
                )}

            </Box>
        </Container>
    );
};

export default BuildPlansPage;
