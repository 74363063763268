import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Container, Box, Typography, Grid, Button } from '@mui/material';
import useCustomTranslation from "../../hooks/useCustomTranslation";

import axiosServices from '../../utilities/axios';
import PDFFileUploader from './PDFFileUploader';
import FormFields from './FormFields';
import {SuccessToast} from "../SuccessToast";
import {ErrorToast} from "../ErrorToast";

const PdfUploader = ({ uploadEndpoint, additionalFields }) => {
    const { translate } = useCustomTranslation();

    const [pdfFiles, setPdfFiles] = useState([]);
    const [fileBinaries, setFileBinaries] = useState({});
    const [formData, setFormData] = useState({});

    const handleUpload = () => {
        const data = {
            ...formData,
            documentData: fileBinaries[pdfFiles[0]?.name]  // Base64 encoded PDF file
        };

        axiosServices.post(uploadEndpoint, data)
            .then(response => {
                SuccessToast('PDF file uploaded successfully');
            })
            .catch((error) => {
                ErrorToast(`Failed to upload PDF file: ${error.message}`);
            });
    };

    return (
        <Container component="main" maxWidth="md">
            <Box sx={{ my: 4 }}>
                <Grid container justifyContent="center">
                    <Grid item xs={12} md={8}>
                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <Typography component="h1" variant="h5">
                                {translate('Upload PDF File')}
                            </Typography>
                            <Box sx={{ mt: 3 }}>
                                <PDFFileUploader pdfFiles={pdfFiles} setPdfFiles={setPdfFiles} setFileBinaries={setFileBinaries} />
                                <FormFields formData={formData} setFormData={setFormData} additionalFields={additionalFields} />
                            </Box>
                            <Button
                                variant="contained"
                                color="primary"
                                disabled={pdfFiles.length === 0 || !formData.description}
                                onClick={handleUpload}
                                sx={{ mt: 3 }}
                            >
                                {translate('Upload')}
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Container>
    );
};

PdfUploader.propTypes = {
    uploadEndpoint: PropTypes.string.isRequired,
    additionalFields: PropTypes.array.isRequired
};

export default PdfUploader;