import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Container,
    Box,
    Typography,
    Grid,
    Button,
    Checkbox,
    FormControlLabel,
    Paper,
    CircularProgress,
    Snackbar,
    Alert,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import SignatureCanvas from 'react-signature-canvas';
import { completeAgreeAndSignPage } from '../../reducers/benefitEnrollmentSlice';
import useCustomTranslation from '../../hooks/useCustomTranslation';
import Branding from '../Branding';
import AgreeAndSignModal from './AgreeAndSignModal';
import { useEnrollWithDependentsMutation } from '../../reducers/enrollmentApiSlice';
import useGetEmployerCode from '../../hooks/useGetEmployerCode';
import logoImage from '../../assets/logo/default_logo.png';

const AgreeAndSignPage = ({ onNext }) => {
    const dispatch = useDispatch();
    const { translate } = useCustomTranslation();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const dependentsCoverage = useSelector((state) => state.planCoverage.selectedPlansWithDependents);
    const waiverReasons = useSelector((state) => state.benefitEnrollment.waiverReasons);
    const formData = useSelector((state) => state.benefitEnrollment.formData);

    const [agreeToTerms, setAgreeToTerms] = useState(false);
    const [signature, setSignature] = useState(null);
    const [modalOpen, setModalOpen] = useState(false);
    const [modalMessage, setModalMessage] = useState({ title: '', description: '' });
    const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });

    const sigCanvas = useRef(null);

    const [enrollWithDependents, { isLoading: isEnrolling }] = useEnrollWithDependentsMutation();
    const { employerCode, isLoading: isEmployerCodeLoading } = useGetEmployerCode({
        userEmail: formData.email,
        shouldRedirect: false,
    });

    useEffect(() => {
        const resizeCanvas = () => {
            if (sigCanvas.current) {
                const canvas = sigCanvas.current.getCanvas();
                const context = canvas.getContext('2d');
                const ratio = Math.max(window.devicePixelRatio || 1, 1);

                canvas.width = canvas.offsetWidth * ratio;
                canvas.height = canvas.offsetHeight * ratio;
                context.scale(ratio, ratio);
            }
        };

        resizeCanvas();
        window.addEventListener('resize', resizeCanvas);

        return () => {
            window.removeEventListener('resize', resizeCanvas);
        };
    }, []);

    const handleAgreeChange = (event) => {
        setAgreeToTerms(event.target.checked);
    };

    const handleClearSignature = () => {
        sigCanvas.current.clear();
        setSignature(null);
    };

    const handleSaveSignature = () => {
        if (!sigCanvas.current.isEmpty()) {
            setSignature(sigCanvas.current.getTrimmedCanvas().toDataURL('image/png'));
        }
    };

    const formatEnrollmentData = (plan, dependentsCoverage) => {
        const currentDate = new Date().toISOString();
        const formattedDate = currentDate.split('T')[0];

        const isWaived = plan.decision === 'waive';

        // Map dependents to only include their IDs
        const dependentIds = Object.values(dependentsCoverage.dependentsCoverage[plan.planId] || {})
            .filter(dep => dep.isCovered)
            .map(dep => dep.dependentId);

        let hasSpouse = false;
        let numberOfChildren = 0;

        dependentIds.forEach(dependentId => {
            const dep = dependentsCoverage.dependentsCoverage[plan.planId][dependentId];
            if (dep.relationshipType === 'Spouse/Domestic Partner') {
                hasSpouse = true;
            } else if (dep.relationshipType === 'Child') {
                numberOfChildren += 1;
            }
        });

        let coveredIndividuals = 'Employee';
        if (hasSpouse && numberOfChildren === 0) {
            coveredIndividuals = 'Employee + Spouse';
        } else if (!hasSpouse && numberOfChildren === 1) {
            coveredIndividuals = 'Employee + Child';
        } else if (hasSpouse || numberOfChildren > 1) {
            coveredIndividuals = 'Family';
        }

        return {
            offeringId: 0,  // Set offeringId to 0
            effectiveDate: formattedDate,
            termDate: formattedDate,
            enrollmentTimestamp: currentDate,
            offeringName: plan.productName,
            offeringWaiver: isWaived,
            totalPremium: 0, // or assign the correct value
            employerContribution: 0, // or assign the correct value
            employeeContribution: 0, // or assign the correct value
            waiverReason: isWaived ? dependentsCoverage.waiverReasons[plan.planId] || '' : '',
            coveredIndividuals,
            planId: isWaived ? null : plan.planId,
            coverageAmount: 0, // or assign the correct value
            beneficiary: '', // if applicable
            policyType: '', // if applicable
            guaranteedIssue: true, // or assign the correct value
            ratePerThousand: 0, // or assign the correct value
            cost: 0, // or assign the correct value
            insuranceCarrier: isWaived ? null : plan.carrierName,
            additionalInfo: isWaived
                ? JSON.stringify({ waiverReason: dependentsCoverage.waiverReasons[plan.planId] || '' })
                : '{}',
            benefitTypeId: plan.benefitTypeId,
            employeeBenefitEnrollment: {
                id: 0, // or assign the correct value
                accountEmail: formData.email,
                employerCode,
                offeringId: 0,  // Set offeringId to 0
                enrollmentStatus: 'pending',
                dependentIds: dependentIds.length > 0 ? dependentIds : null,
            },
        };
    };

    const handleSubmit = async () => {
        if (agreeToTerms && signature) {
            try {
                await Promise.all(
                    dependentsCoverage.selectedPlans.map(plan =>
                        enrollWithDependents(formatEnrollmentData(plan, dependentsCoverage)).unwrap()
                    )
                );
                dispatch(completeAgreeAndSignPage());
                setSnackbar({
                    open: true,
                    message: translate('Enrollment submitted successfully'),
                    severity: 'success',
                });
                onNext();
            } catch (error) {
                console.error('Enrollment error:', error);
                setModalMessage({
                    title: translate('Submission Error'),
                    description: translate('There was an error submitting your enrollment. Please try again.'),
                });
                setModalOpen(true);
            }
        } else {
            setModalMessage({
                title: translate('Incomplete Submission'),
                description: translate('Please agree to the terms and provide a signature.'),
            });
            setModalOpen(true);
        }
    };

    const handleCloseModal = () => {
        setModalOpen(false);
    };

    const handleCloseSnackbar = () => {
        setSnackbar(prev => ({ ...prev, open: false }));
    };

    if (isEmployerCodeLoading) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" minHeight="60vh">
                <CircularProgress />
            </Box>
        );
    }

    return (
        <Container component="main" maxWidth="md" sx={{ mb: 4 }}>
            <Paper elevation={3} sx={{ p: { xs: 2, sm: 4 }, mt: 4 }}>
                <Box sx={{ textAlign: 'center', mb: 4 }}>
                    <Branding logoUrl={logoImage} width={isMobile ? '200px' : '250px'} height="75px" alt="Company Logo" />
                    <Typography component="h1" variant="h5" sx={{ mt: 2 }}>
                        {translate('Agreement and Signature')}
                    </Typography>
                    <Typography variant="body1" sx={{ mt: 1 }}>
                        {translate('Please review your selections and sign the agreement.')}
                    </Typography>
                </Box>

                <Grid container spacing={2}>
                    {dependentsCoverage.selectedPlans.map((plan, index) => (
                        <Grid item xs={12} key={index}>
                            <Paper variant="outlined" sx={{ p: 2, backgroundColor: theme.palette.background.default }}>
                                <Typography variant="h6" sx={{ mb: 1, color: theme.palette.primary.main }}>
                                    {plan.productName} {plan.decision === 'waive' ? `(${translate('Waived')})` : ''}
                                </Typography>
                                <Typography variant="body2" sx={{ mb: 1 }}>
                                    {plan.benefitTypeName}
                                </Typography>
                                {plan.decision !== 'waive' && (
                                    <>
                                        <Typography variant="body2">
                                            <strong>{translate('Carrier')}:</strong> {plan.carrierName}
                                        </Typography>
                                        <Typography variant="body2">
                                            <strong>{translate('Covered Individuals')}:</strong>{' '}
                                            {formatEnrollmentData(plan, dependentsCoverage).coveredIndividuals}
                                        </Typography>
                                        <Typography variant="body2">
                                            <strong>{translate('Covered Dependents')}:</strong>{' '}
                                            {Object.values(dependentsCoverage.dependentsCoverage[plan.planId] || {})
                                                .filter(dep => dep.isCovered)
                                                .map(dep => `${dep.person.firstName} ${dep.person.lastName}`)
                                                .join(', ') || translate('None')}
                                        </Typography>
                                    </>
                                )}
                                {plan.decision === 'waive' && (
                                    <Typography variant="body2">
                                        <strong>{translate('Waiver Reason')}:</strong> {dependentsCoverage.waiverReasons[plan.planId] || translate('No reason provided')}
                                    </Typography>
                                )}
                            </Paper>
                        </Grid>                    ))}

                    <Grid item xs={12}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={agreeToTerms}
                                    onChange={handleAgreeChange}
                                    color="primary"
                                    inputProps={{ 'aria-label': 'Agree to terms and conditions' }}
                                />
                            }
                            label={translate('I agree to the terms and conditions')}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <Typography variant="h6" sx={{ mb: 1 }}>
                            {translate('Signature')}
                        </Typography>
                        <Box
                            sx={{
                                border: '2px dashed grey',
                                borderRadius: 1,
                                mb: 2,
                                textAlign: 'center',
                                position: 'relative',
                                width: '100%',
                                height: { xs: 150, sm: 200, md: 250, lg: 300 },
                            }}
                        >
                            <SignatureCanvas
                                ref={sigCanvas}
                                penColor="black"
                                canvasProps={{
                                    width: '100%',
                                    height: '100%',
                                    className: 'sigCanvas',
                                    style: { borderRadius: 4, width: '100%', height: '100%' },
                                    'aria-label': 'Signature canvas',
                                }}
                                onEnd={handleSaveSignature}
                            />
                            {!signature && (
                                <Typography
                                    variant="body2"
                                    color="textSecondary"
                                    sx={{
                                        position: 'absolute',
                                        top: '50%',
                                        left: '50%',
                                        transform: 'translate(-50%, -50%)',
                                        pointerEvents: 'none',
                                    }}
                                >
                                    {translate('Please provide your signature above')}
                                </Typography>
                            )}
                        </Box>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Button
                                onClick={handleClearSignature}
                                variant="outlined"
                                color="secondary"
                                aria-label="Clear signature"
                                disabled={!signature}
                            >
                                {translate('Clear Signature')}
                            </Button>
                            <Button
                                onClick={handleSubmit}
                                variant="contained"
                                color="primary"
                                aria-label="Submit enrollment"
                                disabled={isEnrolling || !agreeToTerms || !signature}
                            >
                                {isEnrolling ? <CircularProgress size={24} /> : translate('Submit Enrollment')}
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
            </Paper>

            <AgreeAndSignModal open={modalOpen} onClose={handleCloseModal} message={modalMessage} />

            <Snackbar
                open={snackbar.open}
                autoHideDuration={6000}
                onClose={handleCloseSnackbar}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
                <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
                    {snackbar.message}
                </Alert>
            </Snackbar>
        </Container>
    );
};

export default AgreeAndSignPage;
