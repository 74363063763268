import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Container,
    Paper,
    Box,
    Divider,
    Stepper,
    Step,
    StepLabel,
    StepContent,
    useTheme,
    useMediaQuery,
    CircularProgress
} from '@mui/material';
import { completeReviewPage, completeAgreeAndSignPage, updateDependentsData } from '../../reducers/benefitEnrollmentSlice';
import useCustomTranslation from "../../hooks/useCustomTranslation";
import { formatEnrollment } from '../../reducers/formatEnrollmentSlice';
import {
    useGetGendersQuery,
    useGetMaritalStatusesQuery,
    useGetAllRateTypesQuery,
    useGetBenefitTypesQuery,
    useGetAllRelationshipsQuery,
    useGetAddressTypesQuery,
    useGetAllRegionsQuery,
    useGetAllEmployeeStatusesQuery,
    useGetContactByEmailQuery,
    useGetDependentsByAccountEmailQuery
} from "../../reducers/enrollmentApiSlice";
import AuthTokenService from "../../services/AuthTokenService";
import Header from './Header';
import PersonalInformation from './PersonalInformation';
import DependentsInformation from './DependentsInformation';
import AddressInformation from './AddressInformation';
import SelectedPlans from './SelectedPlans';
import CompletionMessage from "./CompletionMessage";
import NavigationButtons from "./NavigationButtons";

const steps = [
    'Personal Information',
    'Dependents Information',
    'Address Information',
    'Selected Plans'
];

const ReviewPage = ({ onNavigateToAccountPage, onNavigateToDependentPage, onNavigateToAddressPage, onNavigateToPlanSelectionPage, onNext }) => {
    const dispatch = useDispatch();
    const { translate } = useCustomTranslation();
    const { user: userEmail } = AuthTokenService.getAuthInfo();
    const selectedPlans = useSelector((state) => state.selectedPlan);
    const reviewPage = useSelector((state) => state.benefitEnrollment.reviewPage);
    const formData = useSelector((state) => state.benefitEnrollment.formData);
    const addressesData = useSelector((state) => state.benefitEnrollment.addressesData);
    const [dependentsCoverage, setDependentsCoverage] = useState({});
    const [activeStep, setActiveStep] = useState(0);

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    // Fetch data using the provided hooks
    const { data: genders, isLoading: isLoadingGenders } = useGetGendersQuery();
    const { data: maritalStatuses, isLoading: isLoadingMaritalStatuses } = useGetMaritalStatusesQuery();
    const { data: rateTypes, isLoading: isLoadingRateTypes } = useGetAllRateTypesQuery();
    const { data: benefitTypes, isLoading: isLoadingBenefitTypes } = useGetBenefitTypesQuery();
    const { data: relationships, isLoading: isLoadingRelationships } = useGetAllRelationshipsQuery();
    const { data: addressTypes, isLoading: isLoadingAddressTypes } = useGetAddressTypesQuery();
    const { data: regions, isLoading: isLoadingRegions } = useGetAllRegionsQuery();
    const { data: employeeStatuses, isLoading: isLoadingEmployeeStatuses } = useGetAllEmployeeStatusesQuery();
    const { data: contactDetails, isLoading: isLoadingContactDetails } = useGetContactByEmailQuery({ email: userEmail });
    const { data: dependentsData, isLoading: isLoadingDependents } = useGetDependentsByAccountEmailQuery({ email: userEmail });

    const isLoading = isLoadingGenders || isLoadingMaritalStatuses || isLoadingRateTypes ||
        isLoadingBenefitTypes || isLoadingRelationships || isLoadingAddressTypes ||
        isLoadingRegions || isLoadingEmployeeStatuses || isLoadingContactDetails || isLoadingDependents;

    useEffect(() => {
        if (dependentsData) {
            const processedDependents = dependentsData.map(dependent => ({
                firstName: dependent.person.firstName,
                lastName: dependent.person.lastName,
                dob: dependent.person.dateOfBirth,
                gender: dependent.person.genderId,
                ssn: dependent.person.socialSecurityNumber,
                relationship: {
                    relationshipId: dependent.relationship.relationshipId,
                    relationshipName: dependent.relationship.relationshipName
                },
                isActive: dependent.isActive
            }));
            dispatch(updateDependentsData(processedDependents));
        }
    }, [dependentsData, dispatch]);

    const handleDependentChange = (planId, dependentId) => {
        setDependentsCoverage(prevState => ({
            ...prevState,
            [planId]: {
                ...prevState[planId],
                [dependentId]: !prevState[planId]?.[dependentId]
            }
        }));
    };

    const handleSubmit = () => {
        console.log('Submitting enrollment with data:', {
            selectedPlans,
            formData,
            dependentsData,
            dependentsCoverage
        });

        dispatch(formatEnrollment({
            selectedPlans,
            formData,
            dependentsData,
            dependentsCoverage
        }));

        dispatch(completeReviewPage());
        dispatch(completeAgreeAndSignPage());
        onNext();
    };

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    if (isLoading) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
                <CircularProgress />
            </Box>
        );
    }

    const updatedFormData = {
        ...formData,
        phoneNumber: contactDetails?.phoneNumber || ''
    };

    return (
        <Container component="main" maxWidth="lg" sx={{ py: 2 }}>
            <Paper elevation={3} sx={{ p: { xs: 2, md: 4 }, mt: { xs: 2, md: 4 }, mb: 4 }}>
                <Header isMobile={isMobile} />

                <Divider sx={{ my: isMobile ? 2 : 3 }} />

                <Stepper activeStep={activeStep} orientation="vertical">
                    {steps.map((label, index) => (
                        <Step key={label}>
                            <StepLabel>{translate(label)}</StepLabel>
                            <StepContent>
                                <Box sx={{ mt: 2, mb: 1 }}>
                                    {index === 0 && (
                                        <PersonalInformation
                                            onNavigateToAccountPage={onNavigateToAccountPage}
                                            formData={updatedFormData}
                                            genders={genders}
                                            maritalStatuses={maritalStatuses}
                                            employeeStatuses={employeeStatuses}
                                        />
                                    )}
                                    {index === 1 && (
                                        <DependentsInformation
                                            dependentsData={dependentsData}
                                            genders={genders}
                                            relationshipTypes={relationships}
                                            onNavigateToDependentPage={onNavigateToDependentPage}
                                        />
                                    )}
                                    {index === 2 && <AddressInformation addressesData={addressesData} addressTypes={addressTypes} regions={regions} onNavigateToAddressPage={onNavigateToAddressPage}/>}
                                    {index === 3 && (
                                        <SelectedPlans
                                            selectedPlans={selectedPlans}
                                            dependentsData={dependentsData}
                                            dependentsCoverage={dependentsCoverage}
                                            onDependentChange={handleDependentChange}
                                            benefitTypes={benefitTypes}
                                            rateTypes={rateTypes}
                                            onNavigateToPlanSelectionPage={onNavigateToPlanSelectionPage}
                                        />
                                    )}
                                </Box>
                                <NavigationButtons
                                    index={index}
                                    stepsLength={steps.length}
                                    onBack={handleBack}
                                    onNext={index === steps.length - 1 ? handleSubmit : handleNext}
                                />
                            </StepContent>
                        </Step>
                    ))}
                </Stepper>
                <CompletionMessage reviewPage={reviewPage} />
            </Paper>
        </Container>
    );
};

export default ReviewPage;