import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Container, Box, Typography, Grid, FormControl, InputLabel, Select, MenuItem, Alert } from '@mui/material';
import { useDispatch } from 'react-redux';
import { useGetCarrierPlansByCarrierIdQuery, useGetBenefitTypesQuery } from '../reducers/enrollmentApiSlice';
import useCustomTranslation from '../hooks/useCustomTranslation';
import { setSelectedPlan } from '../reducers/createInsurancePlanSlice';

const PlanDropdown = ({ carrierId, onSelectionChange, onPlansLoaded }) => {
    const {
        data: plans = [],
        error,
        isLoading,
    } = useGetCarrierPlansByCarrierIdQuery(carrierId || '');

    const {
        data: benefitTypes = [],
        isLoading: benefitTypesLoading,
        error: benefitTypesError,
    } = useGetBenefitTypesQuery();

    const [selectedPlanId, setSelectedPlanId] = useState('');
    const { translate } = useCustomTranslation();
    const dispatch = useDispatch();

    // Effect to handle when plans are loaded
    useEffect(() => {
        if (!isLoading && plans && onPlansLoaded) {
            onPlansLoaded(plans);
        }
    }, [plans, isLoading, onPlansLoaded]);

    // Effect to handle plan selection
    useEffect(() => {
        if (onSelectionChange && !isLoading && !benefitTypesLoading) {
            if (Array.isArray(plans) && plans.length > 0 && Array.isArray(benefitTypes) && benefitTypes.length > 0) {
                const selectedPlanObject = plans.find(plan => plan.planId === selectedPlanId);

                if (selectedPlanObject) {
                    const benefitType = benefitTypes.find(bt => bt.benefitTypeId === selectedPlanObject.benefitTypeId);
                    const planWithBenefitType = {
                        ...selectedPlanObject,
                        benefitType: benefitType || { benefitTypeId: 0, typeName: 'Unknown' }
                    };
                    onSelectionChange(planWithBenefitType);
                    dispatch(setSelectedPlan(planWithBenefitType));
                } else if (selectedPlanId === '') {
                    onSelectionChange(null);
                    dispatch(setSelectedPlan(null));
                }
            } else if (selectedPlanId === '') {
                // User cleared the selection
                onSelectionChange(null);
                dispatch(setSelectedPlan(null));
            } else {
                // Do not call onSelectionChange(null) here
            }
        }
    }, [selectedPlanId, onSelectionChange, plans, dispatch, benefitTypes, isLoading, benefitTypesLoading]);

    const handleDropdownChange = (event) => {
        setSelectedPlanId(event.target.value);
    };

    // Display loading and error messages
    if (isLoading || benefitTypesLoading) {
        return <Typography>{translate('Loading plans...')}</Typography>;
    }

    if (error || benefitTypesError) {
        return <Typography color="error">{translate('Failed to load plans')}</Typography>;
    }

    // Display message when no plans are available
    if (!Array.isArray(plans) || plans.length === 0) {
        return <Typography>{translate('No plans available')}</Typography>;
    }

    return (
        <Container component="main" maxWidth="md">
            <Box sx={{ my: 4 }}>
                <Grid container justifyContent="center">
                    <Grid item xs={12} md={8}>
                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <Box sx={{ mt: 1, width: '100%' }}>
                                <FormControl fullWidth>
                                    <InputLabel>{translate('Select Plan')}</InputLabel>
                                    <Select
                                        value={selectedPlanId}
                                        onChange={handleDropdownChange}
                                        label={translate('Select Plan')}
                                    >
                                        <MenuItem value="">
                                            <em>{translate('None')}</em>
                                        </MenuItem>
                                        {plans.map((plan) => (
                                            <MenuItem key={plan.planId} value={plan.planId}>
                                                {translate(plan.productName)}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Container>
    );
};

PlanDropdown.propTypes = {
    carrierId: PropTypes.number.isRequired,
    onSelectionChange: PropTypes.func.isRequired,
    onPlansLoaded: PropTypes.func,
};

export default PlanDropdown;
