import {useEffect} from "react";
import axiosServices from "../utilities/axios";
import axios from "axios";
import AuthTokenService from "../services/AuthTokenService";
import {logDebug, logError, logInfo} from "../utilities/Logger";
import {ErrorToast} from "./ErrorToast";
import {useLogout} from "../hooks/useLogout";

function AxiosInterceptor({children}) {

    const handleLogout = useLogout();

    useEffect(() => {

        // Request interceptor to log outgoing requests and attach the auth token.
        /** @return {number} */
        const requestInterceptor = axiosServices.interceptors.request.use(
            config => {
                const authToken = AuthTokenService.getAuthInfo().authToken; // Get the auth token
                if (authToken) {
                    config.headers.Authorization = `Bearer ${authToken}`; // Set the Authorization header
                }
                const requestDetails = {
                    url: config.url,
                    method: config.method,
                    headers: config.headers,
                };
                const requestMessage = `Sending request to ${requestDetails.url} using ${requestDetails.method}`;
                return config;
            },
            error => {
                logError(`Request error: ${error.message}`, 'red');
                return Promise.reject(error);
            }
        );

        // Response interceptor to handle and log responses.
        /** @return {number} */
        const responseInterceptor = axiosServices.interceptors.response.use(
            response => {
                const responseDetails = {
                    url: response.config.url,
                    method: response.config.method,
                    status: response.status,
                };
                const responseMessage = `Received response from ${responseDetails.url}: Status ${responseDetails.status}`;
                return response;
            },
            error => {
                const errorDetails = {
                    message: error.response?.data?.message || 'An error occurred',
                    status: error.response?.status,
                    statusText: error.response?.statusText,
                    url: error.config?.url,
                    method: error.config?.method,
                    headers: error.response?.headers,
                };
                const errorMessage = `API Error: ${errorDetails.message}, Status: ${errorDetails.status}, Method: ${errorDetails.method}, URL: ${errorDetails.url}`;
                logError(errorMessage, 'red');  // Already logging detailed errors, enhanced with potential status code checks

                ErrorToast(`Request failed: ${errorDetails.message} (Status: ${errorDetails.status})`);  // Sensitive info should be monitored

                // Logout the user and redirect them to the login screen if they try to access an endpoint without the proper claims.
                if(+error.response.status === 401) {
                    handleLogout();
                }

                return Promise.reject(error);
            }
        );

        function cleanup() {
            axios.interceptors.response.eject(requestInterceptor);
            axios.interceptors.response.eject(responseInterceptor);
        }

        return cleanup;
    }, []);

    return <>{children}</>;
}

export default AxiosInterceptor;