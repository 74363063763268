import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Paper, Typography, Grid, Box, Button, Dialog, DialogContent, CardActions, CircularProgress } from '@mui/material';
import { useLazyGetDocumentsByPlanIdQuery, useGetBenefitTypesQuery, useGetAllCarriersQuery } from '../../reducers/enrollmentApiSlice';
import DocumentViewer from '../PlanSelection/DocumentViewer';
import { gowellColors } from "../../themes/palettes/gowellColors";
import FlexibleCarrierLogo from "../Carrier/FlexibleCarrierLogo";
import { useDispatch } from 'react-redux';
import { addOrUpdateSelection } from '../../reducers/selectedPlanSlice';
import ReadOnlyEmployerAdminRatesTable from "../RatesTable/ReadOnlyEmployerAdminRatesTable";

const PlanItemWithEnroll = ({ plan, children, onCompareToggle, isSelectedForComparison, hideCompare, handleEnrollClick, employerCode }) => {
    const [open, setOpen] = useState(false);
    const [documents, setDocuments] = useState([]);
    const [carrierName, setCarrierName] = useState('Loading...');
    const [benefitTypeName, setBenefitTypeName] = useState('Loading...');
    const [showRates, setShowRates] = useState(false);
    const [getDocumentsByPlanId, { isLoading, isError, data }] = useLazyGetDocumentsByPlanIdQuery();
    const { data: benefitTypes, isLoading: isBenefitTypesLoading, error: benefitTypesError } = useGetBenefitTypesQuery();
    const { data: carriers, isLoading: isCarriersLoading, error: carriersError } = useGetAllCarriersQuery();
    const dispatch = useDispatch();

    useEffect(() => {
        console.log('PlanItemWithEnroll: Initial props', {
            plan,
            onCompareToggle: !!onCompareToggle,
            isSelectedForComparison,
            hideCompare,
            handleEnrollClick: !!handleEnrollClick,
            employerCode
        });
    }, []);

    useEffect(() => {
        if (carriers && carriers.length > 0) {
        }
    }, [benefitTypes, carriers]);

    useEffect(() => {
        if (benefitTypesError) {
            console.error('Error fetching benefit types:', benefitTypesError);
        }
        if (carriersError) {
            console.error('Error fetching carriers:', carriersError);
        }
    }, [benefitTypesError, carriersError]);

    useEffect(() => {
        if (!isCarriersLoading && carriers) {
            const carrier = carriers.find(c => c.carrierId === plan.carrierId);
            if (carrier) {
                setCarrierName(carrier.carrierName);
            } else {
                setCarrierName('Unknown Carrier');
            }
        }
    }, [carriers, isCarriersLoading, plan.carrierId]);

    useEffect(() => {
        if (!isBenefitTypesLoading && benefitTypes) {
            const benefitType = benefitTypes.find(bt => bt.benefitTypeId === plan.benefitTypeId);
            if (benefitType) {
                setBenefitTypeName(benefitType.typeName);
            } else {
                setBenefitTypeName('Unknown Benefit Type');
            }
        }
    }, [benefitTypes, isBenefitTypesLoading, plan.benefitTypeId]);

    const handlePlanSummaryClick = async () => {
        if (!plan.planId) {
            console.error('planId is undefined or null.');
            return;
        }
        try {
            const result = await getDocumentsByPlanId(plan.planId);
            if (result.data) {
                setDocuments(result.data);
                setOpen(true);
            } else {
                console.error('No data returned for the document.');
            }
        } catch (error) {
            console.error('Error fetching document:', error);
        }
    };

    const truncateProductName = (name, maxLength) => {
        if (name.length <= maxLength) {
            return name;
        }
        return name.slice(0, maxLength) + '...';
    };

    const handleClose = () => {
        setOpen(false);
        setDocuments([]);
    };

    const handleEnroll = () => {
        handleEnrollClick();
        dispatch(addOrUpdateSelection({
            ...plan,
            decision: 'enroll',
        }));
    };

    const handleViewRates = () => {
        setShowRates(true);
    };

    const handleCloseRates = () => {
        setShowRates(false);
    };

    return (
        <Paper
            elevation={3}
            sx={{
                padding: 2,
                margin: 2,
                position: 'relative',
                minHeight: 300,
                minWidth: 250,
                border: isSelectedForComparison ? `2px solid ${gowellColors.blue.base}` : 'none',
                backgroundColor: isSelectedForComparison ? gowellColors.grey.light : gowellColors.grey.dark
            }}
        >
            <Grid container spacing={2} direction="column" alignItems="center">
                {!hideCompare && (
                    <Grid item xs={12} display="flex" justifyContent="center" sx={{ width: '100%' }}>
                        <Button
                            variant="outlined"
                            size="small"
                            onClick={() => onCompareToggle(plan)}
                            sx={{
                                marginBottom: 2,
                                borderColor: isSelectedForComparison ? gowellColors.white : gowellColors.grey.dark,
                                color: isSelectedForComparison ? gowellColors.blue.base : gowellColors.white,
                                backgroundColor: isSelectedForComparison ? gowellColors.white : gowellColors.grey.dark,
                                '&:hover': {
                                    backgroundColor: isSelectedForComparison ? gowellColors.grey.light : gowellColors.grey.darker,
                                    borderColor: isSelectedForComparison ? gowellColors.grey.light : gowellColors.grey.darker,
                                },
                                minWidth: '150px'
                            }}
                        >
                            {isSelectedForComparison ? 'Remove from Compare' : 'Compare'}
                        </Button>
                    </Grid>
                )}
                <Grid item xs={12} display="flex" justifyContent="center">
                    <Box
                        sx={{
                            width: '100%',
                            height: 'auto',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            padding: 2,
                        }}
                    >
                        <FlexibleCarrierLogo logoId={plan.carrierId.toString()} width={100} height={100} />
                    </Box>
                </Grid>
                <Grid item xs={12} display="flex" justifyContent="center">
                    <Box display="flex" flexDirection="column" justifyContent="center" height="100%" sx={{ textAlign: 'center' }}>
                        <Typography
                            variant="h6"
                            gutterBottom
                            noWrap
                            sx={{
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                                maxWidth: '100%',
                                color: gowellColors.black,
                            }}
                            title={plan.productName}
                        >
                            {truncateProductName(plan.productName, 20)}
                        </Typography>

                        <Typography variant="body2" component="div" sx={{ color: gowellColors.black }}>
                            <Box component="span" fontWeight="fontWeightBold">Carrier:</Box> {carrierName}
                        </Typography>
                        <Typography variant="body2" component="div" sx={{ color: gowellColors.black }}>
                            <Box component="span" fontWeight="fontWeightBold">Benefit Type:</Box> {benefitTypeName}
                        </Typography>
                        <CardActions sx={{ flexDirection: 'column', alignItems: 'center', paddingTop: 2, width: '100%' }}>
                            <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                {plan.planId && (
                                    <Button
                                        variant="contained"
                                        size="small"
                                        onClick={handlePlanSummaryClick}
                                        disabled={isLoading}
                                        sx={{ mb: 1, textAlign: 'center', backgroundColor: gowellColors.blue.base }}
                                        fullWidth
                                    >
                                        Summary
                                    </Button>
                                )}
                                <Button
                                    variant="contained"
                                    size="small"
                                    onClick={handleEnroll}
                                    sx={{
                                        mb: 1,
                                        textAlign: 'center',
                                        backgroundColor: gowellColors.pointilSociety.base,
                                        '&:hover': {
                                            backgroundColor: gowellColors.pointilSociety.dark,
                                        },
                                    }}
                                    fullWidth
                                >
                                    {isLoading ? <CircularProgress size={24} /> : 'Enroll'}
                                </Button>
                                <Button
                                    variant="contained"
                                    size="small"
                                    onClick={handleViewRates}
                                    sx={{ mb: 1, textAlign: 'center', backgroundColor: gowellColors.sealitePidgeon.base }}
                                    fullWidth
                                >
                                    View Rates
                                </Button>
                                {children}
                            </Box>
                        </CardActions>
                    </Box>
                </Grid>
            </Grid>
            <Dialog open={open} onClose={handleClose} fullWidth maxWidth="lg">
                <DialogContent>
                    {
                        documents.length !== 0 ?
                            <>
                                <Typography variant='h4' gutterBottom>Plan Summary:</Typography>
                                {
                                    documents.map((document, index) => {
                                        return <DocumentViewer key={`${document.documentName}-${index}`} documentData={document.documentData} />;
                                    })
                                }
                            </>
                            :  <Typography variant='h4' gutterBottom>No plan summaries found.</Typography>
                    }
                </DialogContent>
            </Dialog>
            <Dialog open={showRates} onClose={handleCloseRates} fullWidth maxWidth="lg">
                <DialogContent>
                    <Typography variant='h4' gutterBottom>Plan Rates:</Typography>
                    <ReadOnlyEmployerAdminRatesTable plan={plan} employerCode={employerCode} />
                </DialogContent>
            </Dialog>
        </Paper>
    );
};

PlanItemWithEnroll.propTypes = {
    plan: PropTypes.shape({
        planId: PropTypes.number.isRequired,
        carrierId: PropTypes.number.isRequired,
        productName: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
        benefitTypeId: PropTypes.number,
        regionIds: PropTypes.arrayOf(PropTypes.number),
    }).isRequired,
    children: PropTypes.node,
    onCompareToggle: PropTypes.func.isRequired,
    isSelectedForComparison: PropTypes.bool.isRequired,
    hideCompare: PropTypes.bool.isRequired,
    handleEnrollClick: PropTypes.func.isRequired,
    employerCode: PropTypes.string.isRequired,
};

export default PlanItemWithEnroll;