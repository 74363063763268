import React from 'react';
import PropTypes from 'prop-types';
import { useGetCarrierLogoQuery } from '../../reducers/enrollmentApiSlice';
import { getDataImageUrlWithHeader } from '../../utilities/image';
import LoadingSpinner from "../LoadingSpinner";

const defaultImage = "https://assets.pokemon.com/assets/cms2/img/pokedex/full//018.png";

function FlexibleCarrierLogo({ logoId, width = 200, height = 200 }) {
    const {
        data: imageData,
        isLoading: imageDataIsLoading,
        isError: imageDataIsError,
    } = useGetCarrierLogoQuery(logoId ? { logoId } : skipToken); // Skip the query if logoId is not valid

    let dataImageUrlWithHeader = imageData ? getDataImageUrlWithHeader(imageData.logoData) : defaultImage;

    if (imageDataIsLoading) {
        return <LoadingSpinner></LoadingSpinner>;
    }

    if (imageDataIsError) {
        return <p>Error loading image</p>;
    }

    return dataImageUrlWithHeader ? (
        <img
            style={{
                width: width,
                height: height,
                objectFit: "contain"
            }}
            alt=""
            src={dataImageUrlWithHeader}
        />
    ) : null;
}

FlexibleCarrierLogo.propTypes = {
    logoId: PropTypes.string.isRequired,
    width: PropTypes.number,
    height: PropTypes.number
};

export default FlexibleCarrierLogo;