import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Grid,
    Container,
    Box,
    Button,
    CircularProgress,
    Alert,
    Snackbar,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Stack,
    useTheme,
    Typography,
    Tooltip,
    Paper
} from '@mui/material';
import {
    useGetAllEmployerPlanSelectionsQuery,
    useGetBenefitTypesQuery,
} from '../../reducers/enrollmentApiSlice';
import PlanItemWithEnroll from "../PlanItem/PlanItemWithEnroll";
import AuthTokenService from "../../services/AuthTokenService";
import useCustomTranslation from "../../hooks/useCustomTranslation";
import { addOrUpdateSelection } from '../../reducers/selectedPlanSlice';
import { setBenefitTypeIds } from '../../reducers/benefitTypeSlice';
import { completePlanSelectionPage } from "../../reducers/benefitEnrollmentSlice";
import useGetEmployerCode from "../../hooks/useGetEmployerCode";
import RateComparison from "../RatesTable/RatesComparison";
import {gowellColors} from "../../themes/palettes/gowellColors";

const PlanSelectionForm = ({ onNext }) => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const [comparePlans, setComparePlans] = useState([]);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const { translate } = useCustomTranslation();
    const selectedBenefitTypeIds = useSelector((state) => state.benefitType.selectedBenefitTypeIds);
    const authInfo = AuthTokenService.getAuthInfo();
    const email = authInfo.user || "default-email@example.com";
    const [currentBenefitTypeIndex, setCurrentBenefitTypeIndex] = useState(0);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [showRateComparison, setShowRateComparison] = useState(false);
    const [selections, setSelections] = useState({});

    const { employerCode, isLoading: isEmployerCodeLoading, isError: isEmployerCodeError } = useGetEmployerCode({ userEmail: email });

    const { data: plans, isLoading: isPlansLoading, isError: isPlansError } = useGetAllEmployerPlanSelectionsQuery(
        { employerCode },
        { skip: !employerCode }
    );

    const { data: benefitTypes, isLoading: isBenefitTypesLoading, isError: isBenefitTypesError } = useGetBenefitTypesQuery();

    useEffect(() => {
        if (plans && benefitTypes) {
            const uniqueBenefitTypeIds = [...new Set(plans.map(plan => plan.benefitTypeId))];
            dispatch(setBenefitTypeIds(uniqueBenefitTypeIds));
        }
    }, [plans, benefitTypes, dispatch]);

    const handleEnroll = (plan) => {
        const { planId, benefitTypeId, carrierId } = plan;
        dispatch(addOrUpdateSelection({ benefitTypeId, planId, carrierId, decision: 'enroll' }));
        setSelections(prev => ({
            ...prev,
            [benefitTypeId]: 'enroll'
        }));
        setSnackbarMessage(translate(`Enrolled in plan: ${planId}`));
        setSnackbarOpen(true);
        checkAndMoveToNext(benefitTypeId);
    };

    const handleWaive = (benefitTypeId) => {
        dispatch(addOrUpdateSelection({ benefitTypeId, planId: null, carrierId: null, decision: 'waive' }));
        setSelections(prev => ({
            ...prev,
            [benefitTypeId]: 'waive'
        }));
        setSnackbarMessage(translate(`Waived benefit type: ${benefitTypeId}`));
        setSnackbarOpen(true);
        checkAndMoveToNext(benefitTypeId);
    };

    const checkAndMoveToNext = (currentBenefitTypeId) => {
        const allSelectionsComplete = selectedBenefitTypeIds.every(id => selections[id]);

        if (allSelectionsComplete) {
            setDialogOpen(true);
        } else {
            const currentIndex = selectedBenefitTypeIds.indexOf(currentBenefitTypeId);
            let nextIndex = (currentIndex + 1) % selectedBenefitTypeIds.length;

            while (selections[selectedBenefitTypeIds[nextIndex]] && nextIndex !== currentIndex) {
                nextIndex = (nextIndex + 1) % selectedBenefitTypeIds.length;
            }

            if (nextIndex !== currentIndex) {
                setCurrentBenefitTypeIndex(nextIndex);
                setComparePlans([]);
                setShowRateComparison(false);
            } else {
                setDialogOpen(true);
            }
        }
    };

    const moveToPreviousBenefitType = () => {
        const prevIndex = (currentBenefitTypeIndex - 1 + selectedBenefitTypeIds.length) % selectedBenefitTypeIds.length;
        setCurrentBenefitTypeIndex(prevIndex);
        setComparePlans([]);
        setShowRateComparison(false);
    };

    const handleCompareToggle = (plan) => {
        setComparePlans((prev) => {
            const isSelected = prev.some((p) => p.planId === plan.planId);
            const sameBenefitType = prev.length === 0 || prev[0].benefitTypeId === plan.benefitTypeId;

            if (isSelected) {
                return prev.filter((p) => p.planId !== plan.planId);
            } else if (prev.length < 3 && sameBenefitType) {
                return [...prev, plan];
            } else if (!sameBenefitType) {
                setSnackbarMessage(translate('You can only compare plans of the same benefit type.'));
                setSnackbarOpen(true);
                return prev;
            } else {
                setSnackbarMessage(translate('You can only compare up to 3 plans at a time.'));
                setSnackbarOpen(true);
                return prev;
            }
        });
    };

    const handleCompareButtonClick = () => {
        setShowRateComparison(true);
        setSnackbarMessage(translate('Comparing selected plans'));
        setSnackbarOpen(true);
    };

    const handleDialogClose = () => {
        setDialogOpen(false);
        setComparePlans([]);
        setShowRateComparison(false);
        dispatch(completePlanSelectionPage());
        onNext();
    };

    if (isEmployerCodeLoading || isPlansLoading || isBenefitTypesLoading) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
                <CircularProgress />
            </Box>
        );
    }

    if (isEmployerCodeError || isPlansError || isBenefitTypesError) {
        return <Alert severity="error">{translate('Error loading data. Please try again later.')}</Alert>;
    }

    const currentBenefitTypeId = selectedBenefitTypeIds[currentBenefitTypeIndex];
    const currentBenefitType = benefitTypes?.find(bt => bt.benefitTypeId === currentBenefitTypeId);
    const filteredPlans = plans.filter(plan => plan.benefitTypeId === currentBenefitTypeId);

    return (
        <Container component="main" maxWidth="lg">
            <Box sx={{ my: 4 }}>
                <Grid container direction="column" spacing={3}>
                    <Grid item>
                        <Typography variant="h5" component="h1" align="center" gutterBottom>
                            {translate('Plan Selection')}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Paper
                            elevation={3}
                            sx={{
                                p: 2,
                                backgroundColor: theme.palette.background.paper,
                                borderRadius: theme.shape.borderRadius,
                            }}
                        >
                            <Typography variant="h6" align="center" gutterBottom>
                                {translate('Current Benefit Type')}
                            </Typography>
                            <Typography
                                variant="h5"
                                align="center"
                                sx={{
                                    color: theme.palette.primary.main,
                                    fontWeight: 'bold',
                                }}
                            >
                                {currentBenefitType?.typeName}
                            </Typography>
                        </Paper>
                    </Grid>
                    <Grid item>
                        <Paper
                            elevation={3}
                            sx={{
                                p: 2,
                                backgroundColor: theme.palette.background.default,
                                borderRadius: theme.shape.borderRadius,
                            }}
                        >
                            <Stack direction="row" spacing={2} justifyContent="center">
                                <Tooltip title={comparePlans.length < 2 ? translate('Select at least 2 plans to compare') : ''}>
                                    <span>
                                        <Button
                                            variant="contained"
                                            sx={{
                                                backgroundColor: theme.palette.primary.main,
                                                color: theme.palette.primary.contrastText,
                                                '&:hover': {
                                                    backgroundColor: theme.palette.primary.dark,
                                                },
                                                '&:disabled': {
                                                    backgroundColor: theme.palette.action.disabledBackground,
                                                    color: theme.palette.action.disabled,
                                                },
                                            }}
                                            onClick={handleCompareButtonClick}
                                            aria-label={translate('Compare selected plans')}
                                            disabled={comparePlans.length < 2}
                                        >
                                            {translate('Compare')}
                                        </Button>
                                    </span>
                                </Tooltip>
                                <Button
                                    variant="outlined"
                                    sx={{
                                        borderColor: theme.palette.secondary.main,
                                        color: theme.palette.secondary.main,
                                        '&:hover': {
                                            backgroundColor: theme.palette.secondary.light,
                                            borderColor: theme.palette.secondary.dark,
                                        },
                                        '&:disabled': {
                                            borderColor: theme.palette.action.disabledBackground,
                                            color: theme.palette.action.disabled,
                                        },
                                    }}
                                    onClick={moveToPreviousBenefitType}
                                    disabled={currentBenefitTypeIndex === 0}
                                    aria-label={translate('Go to previous benefit type')}
                                >
                                    {translate('Previous')}
                                </Button>
                                <Button
                                    variant="contained"
                                    sx={{
                                        backgroundColor: theme.palette.error.main,
                                        color: theme.palette.error.contrastText,
                                        '&:hover': {
                                            backgroundColor: theme.palette.error.dark,
                                        },
                                    }}
                                    onClick={() => handleWaive(currentBenefitType?.benefitTypeId)}
                                    aria-label={translate(`Waive ${currentBenefitType?.typeName}`)}
                                >
                                    {translate('Waive')}
                                </Button>
                            </Stack>
                        </Paper>
                    </Grid>
                </Grid>
            </Box>

            <Grid container spacing={3} sx={{ mt: 2 }}>
                {filteredPlans.map((plan, index) => (
                    <Grid item xs={12} sm={6} md={4} key={index}>
                        <PlanItemWithEnroll
                            plan={plan}
                            onCompareToggle={handleCompareToggle}
                            isSelectedForComparison={comparePlans.some(p => p.planId === plan.planId)}
                            hideCompare={filteredPlans.length <= 1}
                            handleEnrollClick={() => handleEnroll(plan)}
                            employerCode={employerCode}
                        />
                    </Grid>
                ))}
            </Grid>

            {showRateComparison && comparePlans.length > 0 && (
                <Box sx={{ mt: 4 }}>
                    <Typography variant="h6" gutterBottom>{translate('Rate Comparison')}</Typography>
                    <RateComparison plans={comparePlans} employerCode={employerCode} />
                </Box>
            )}

            <Snackbar
                open={snackbarOpen}
                autoHideDuration={3000}
                onClose={() => setSnackbarOpen(false)}
                message={snackbarMessage}
                aria-live="polite"
            />
            <Dialog
                open={dialogOpen}
                onClose={() => setDialogOpen(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle
                    id="alert-dialog-title"
                    sx={{ color: gowellColors.black }} // Set title text to black
                >
                    {translate('Confirmation')}
                </DialogTitle>
                <DialogContent
                    sx={{ color: gowellColors.black }} // Set all text in content to black
                >
                    <DialogContentText id="alert-dialog-description" sx={{ color: 'inherit' }}>
                        {translate('You have completed your selections. Do you want to proceed to the next step?')}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => setDialogOpen(false)}
                        sx={{
                            backgroundColor: gowellColors.heatonColors.base, // Use a gowell color for the background
                            color: theme.palette.primary.contrastText, // Keep the text color consistent
                            '&:hover': {
                                backgroundColor: gowellColors.heatonColors.dark, // Darker shade on hover
                            },
                        }}
                    >
                        {translate('No, review selections')}
                    </Button>

                    <Button
                        onClick={handleDialogClose}
                        sx={{
                            backgroundColor: theme.palette.primary.main,
                            color: theme.palette.primary.contrastText,
                            '&:hover': {
                                backgroundColor: theme.palette.primary.dark,
                            },
                        }}
                        autoFocus
                    >
                        {translate('Yes, proceed')}
                    </Button>
                </DialogActions>
            </Dialog>

        </Container>
    );
};

export default PlanSelectionForm;