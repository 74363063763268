import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography, Paper, Grid, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ReadOnlyEmployerAdminRatesTable from './ReadOnlyEmployerAdminRatesTable';

const RateComparison = ({ plans, employerCode }) => {
    return (
        <Paper sx={{ p: 2, mt: 2 }}>
            <Typography variant="h5" gutterBottom align="center">Rate Comparison</Typography>
            {plans.map((plan) => (
                <Accordion key={plan.planId}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls={`panel-${plan.planId}-content`}
                        id={`panel-${plan.planId}-header`}
                    >
                        <Typography>{plan.productName}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <ReadOnlyEmployerAdminRatesTable plan={plan} employerCode={employerCode} />
                    </AccordionDetails>
                </Accordion>
            ))}
        </Paper>
    );
};

RateComparison.propTypes = {
    plans: PropTypes.arrayOf(
        PropTypes.shape({
            planId: PropTypes.number.isRequired,
            productName: PropTypes.string.isRequired,
        })
    ).isRequired,
    employerCode: PropTypes.string.isRequired,
};

export default RateComparison;