import { HttpMethod } from "../types/httpMethod";

export const uiSupportEndpoints = (builder) => ({
    getGenders: builder.query({
        query: () => ({ url: '/UiSupport/GetAllGenders', method: HttpMethod.Get }),
        /**
         * @returns {GenderDTO[]}
         */
        transformResponse: (response) => {
            if (!(response.data instanceof Array)) {
                return [];
            }
            return response?.data ?? [];
        },
    }),
    getMaritalStatuses: builder.query({
        query: () => ({ url: '/UiSupport/GetAllMaritalStatuses', method: HttpMethod.Get }),
        /**
         * @returns {MaritalStatusDTO[]}
         */
        transformResponse: (response) => {
            if (!(response.data instanceof Array)) {
                return [];
            }
            return response?.data ?? [];
        }
    }),
    getAllRateTypes: builder.query({
        query: () => ({ url: '/UiSupport/GetAllRateTypes', method: HttpMethod.Get }),
        /**
         * @returns {GetAllRateTypesResponse[]}
         */
        transformResponse: (response) => {
            if (!(response.data instanceof Array)) {
                return [];
            }
            return response?.data ?? [];
        }
    }),

    getCarrierPlans: builder.query({
        query: () => ({ url: '/UiSupport/GetAllCarrierPlans', method: HttpMethod.Get }),
        /**
         * @returns {SimpleCarrierPlanDTO[]}
         */
        transformResponse: (response) => {
            if (!(response instanceof Array)) {
                return [];
            }
            return response?.map(
                (carrierPlan) => ({
                    id: carrierPlan.planId,
                    carrierId: carrierPlan.carrierId,
                    name: carrierPlan.productName,
                    description: carrierPlan.description,
                    minEmployees: carrierPlan.minEmployees,
                    maxEmployees: carrierPlan.maxEmployees,
                    availableNationwide: carrierPlan.availableNationwide,
                    benefitTypeName: carrierPlan.benefitTypeName,
                })
            ) ?? [];
        }
    }),
    getCarrierPlansWithRates: builder.query({
        query: () => ({ url: '/UiSupport/GetAllCarrierPlansWithRates', method: HttpMethod.Get }),
        /**
         * @returns {CarrierPlanWithRatesDTO[]}
         */
        transformResponse: (response) => {
            if (!(response instanceof Array)) {
                return [];
            }
            return response;
        }
    }),
    getBenefitTypes: builder.query({
        query: () => ({ url: '/UiSupport/GetAllBenefitTypes', method: HttpMethod.Get }),
        /**
         * @returns {BenefitTypeDTO[]}
         */
        transformResponse: (response) => {
            if (!(response.data instanceof Array)) {
                return [];
            }
            return response?.data ?? [];
        },
    }),
    getAllRelationships: builder.query({
        query: () => ({ url: '/UiSupport/GetAllRelationships', method: HttpMethod.Get }),
        /**
         * @returns {GetAllRelationshipsResponse[]}
         */
        transformResponse: (response) => {
            if (!(response.data instanceof Array)) {
                return [];
            }
            return response?.data ?? [];
        },
    }),
    getAddressTypes: builder.query({
        query: () => ({ url: '/UiSupport/GetAllAddressTypes', method: HttpMethod.Get }),
        /**
         * @returns {AddressTypeDTO[]}
         */
        transformResponse: (response) => {
            if (!(response.data instanceof Array)) {
                return [];
            }
            return response?.data ?? [];
        },
    }),
    getAllRegions: builder.query({
        query: () => ({ url: '/UiSupport/GetAllRegions', method: HttpMethod.Get }),
        /**
         * @returns {RegionDTO[]}
         */
        transformResponse: (response) => {
            if (!(response.data instanceof Array)) {
                return [];
            }
            return response?.data ?? [];
        },
    }),
    getAllEmployeeStatuses: builder.query({
        query: () => ({ url: '/UiSupport/GetAllEmployeeStatuses', method: HttpMethod.Get }),
        /**
         * @returns {EmployeeStatusDTO[]}
         */
        transformResponse: (response) => {
            if (!(response.data instanceof Array)) {
                return [];
            }
            return response?.data ?? [];
        },
    }),
    getAllContributionTypes: builder.query({
        query: () => ({ url: '/UiSupport/GetAllContributionTypes', method: 'GET' }),
        transformResponse: (response) => {
            if (!Array.isArray(response.data)) {
                return [];
            }
            return response?.data ?? [];
        },
    }),
    getAllEmploymentTypes: builder.query({
        query: () => ({ url: '/UiSupport/GetAllEmploymentTypes', method: HttpMethod.Get }),
        transformResponse: (response) => {
            if (!(response.data instanceof Array)) {
                return [];
            }
            return response?.data ?? [];
        },
    }),

    getAllPayPeriods: builder.query({
        query: () => ({ url: '/UiSupport/GetAllPayPeriods', method: HttpMethod.Get }),
        transformResponse: (response) => {
            if (!(response.data instanceof Array)) {
                return [];
            }
            return response?.data ?? [];
        },
    }),

    getAllIndividualTypes: builder.query({
        query: () => ({ url: '/UiSupport/GetAllIndividualTypes', method: HttpMethod.Get }),
        transformResponse: (response) => {
            if (!(response.data instanceof Array)) {
                return [];
            }
            return response?.data ?? [];
        },
    }),
});
