
export const benefitEnrollmentEndpoints = (builder) => ({
    enrollWithDependents: builder.mutation({
        query: (benefitEnrollmentDto) => ({
            url: '/BenefitEnrollment/EnrollWithDependents',
            method: 'POST',  // Replaced HttpMethod.Post with 'POST'
            data: benefitEnrollmentDto
        }),
    }),
    getEnrollmentsByEmail: builder.query({
        query: (accountEmail) => ({
            url: `/BenefitEnrollment/GetEnrollmentsByEmail?accountEmail=${encodeURIComponent(accountEmail)}`,
            method: 'GET',  // Replaced HttpMethod.Get with 'GET'
        }),
        transformResponse: (response) => {
            if (!(response instanceof Array)) {
                return [];
            }
            return response ?? [];
        },
    }),
});
