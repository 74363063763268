import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Typography, Paper, TextField, Grid, Link } from '@mui/material';
import useCustomTranslation from "../../hooks/useCustomTranslation";
import DependentCoverage from './DependentCoverage';
import { updateWaiverReason } from '../../reducers/benefitEnrollmentSlice';
import { updateSelectedPlansWithDependents } from '../../reducers/planCoverageSlice';

const SelectedPlans = ({ selectedPlans, dependentsData, onNavigateToPlanSelectionPage }) => {
    const { translate } = useCustomTranslation();
    const dispatch = useDispatch();
    const waiverReasons = useSelector(state => state.benefitEnrollment.waiverReasons);
    const [dependentsCoverage, setDependentsCoverage] = useState({});
    const [waivedPlans, setWaivedPlans] = useState({});

    useEffect(() => {
    }, [selectedPlans, dependentsData, dependentsCoverage, waiverReasons, waivedPlans]);

    useEffect(() => {
        const initialWaivedPlans = {};
        selectedPlans.forEach(plan => {
            initialWaivedPlans[plan.planId] = plan.decision === 'waive';
        });
        setWaivedPlans(initialWaivedPlans);
    }, [selectedPlans]);

    const handleWaiverReasonChange = (planId, reason) => {
        dispatch(updateWaiverReason({ planId, reason }));
    };

    const handleDependentCoverageChange = (planId, dependent, isCovered) => {
        setDependentsCoverage(prevState => {
            const newState = {
                ...prevState,
                [planId]: {
                    ...prevState[planId],
                    [dependent.dependentId]: {
                        ...dependent,
                        isCovered
                    }
                }
            };
            return newState;
        });
    };

    useEffect(() => {

        // Prepare the full object to be dispatched
        const fullData = {
            selectedPlans,
            dependentsData,
            dependentsCoverage,
            waiverReasons,
            waivedPlans
        };

        dispatch(updateSelectedPlansWithDependents(fullData));
    }, [selectedPlans, dependentsCoverage, waivedPlans, waiverReasons, dependentsData, dispatch]);

    const renderField = (label, value) => {
        if (value === undefined || value === null || value === '') {
            return null;
        }
        return (
            <Grid item xs={12}>
                <Typography variant="subtitle2" component="dt">{translate(label)}:</Typography>
                <Typography variant="body2" component="dd">{value}</Typography>
            </Grid>
        );
    };

    return (
        <Box component="section" aria-labelledby="selected-plans-title">
            <Typography id="selected-plans-title" component="h2" variant="h6" gutterBottom>
                {translate('Selected Plans')}
            </Typography>
            {selectedPlans.length > 0 ? (
                selectedPlans.map(plan => {
                    const isWaived = waivedPlans[plan.planId];
                    return (
                        <Paper key={plan.planId} elevation={3} sx={{ p: 3, mt: 3, mb: 3 }}>
                            <Typography variant="h6" gutterBottom>
                                {plan.productName}
                                {!isWaived && ` (${plan.benefitTypeName})`}
                            </Typography>
                            {isWaived ? (
                                <Box sx={{ mt: 2 }}>
                                    <Typography variant="body1" sx={{ color: 'error.main' }} gutterBottom>
                                        {translate('Plan Waived')}
                                    </Typography>
                                    <TextField
                                        label={translate('Waiver Reason')}
                                        variant="outlined"
                                        fullWidth
                                        margin="normal"
                                        value={waiverReasons[plan.planId] || ''}
                                        onChange={(e) => handleWaiverReasonChange(plan.planId, e.target.value)}
                                    />
                                </Box>
                            ) : (
                                <>
                                    <Grid container spacing={2} component="dl">
                                        {renderField("Description", plan.description)}
                                        {renderField("Carrier", plan.carrierName)}
                                    </Grid>
                                    {dependentsData.length > 0 && (
                                        <DependentCoverage
                                            planId={plan.planId}
                                            dependentsData={dependentsData}
                                            dependentsCoverage={dependentsCoverage[plan.planId] || {}}
                                            onDependentChange={handleDependentCoverageChange}
                                        />
                                    )}
                                </>
                            )}
                        </Paper>
                    );
                })
            ) : (
                <Paper elevation={2} sx={{ p: 3, mt: 2, backgroundColor: '#f5f5f5' }}>
                    <Typography variant="body1" align="center">
                        {translate('No plans selected')}
                    </Typography>
                </Paper>
            )}
            <Box sx={{ mt: 2, textAlign: 'right' }}>
                <Link
                    component="button"
                    variant="body2"
                    onClick={() => {
                        onNavigateToPlanSelectionPage();
                    }}
                    sx={{ textDecoration: 'none' }}
                    aria-label={translate('Edit Selected Plans')}
                >
                    {translate('Edit Selected Plans')}
                </Link>
            </Box>
        </Box>
    );
};

export default SelectedPlans;
