import { HttpMethod } from "../types/httpMethod";

export const accountEndpoints = (builder) => ({

        createAccount: builder.mutation({
            /**
             * @param {CreateAccountRequest} createAccountRequest
             */
            query: (createAccountRequest) => ({
                url: '/Account/CreateAccount',
                method: HttpMethod.Post,
                data: createAccountRequest
            }),
            /**
             * @returns {CreateAccountResponse}
             */
            transformResponse: (response) => response,
        }),
    getAccountDetails: builder.query({
        /**
         * @param {AccountDetailsRequest} accountDetailsRequest
         */
        query: (accountDetailsRequest) => ({
            url: `/Account/GetAccountDetails?email=${encodeURIComponent(accountDetailsRequest.email)}`,
            method: HttpMethod.Get
        }),
        /**
         * @returns {AccountDetailsDTO}
         */
        transformResponse: (response) => response,
    }),
    addPersonalDetails: builder.mutation({
        /**
         * @param {PersonalAndEmploymentDetailsDTO} addPersonalDetailsRequest
         */
        query: (addPersonalDetailsRequest) => ({
            url: '/Account/AddPersonalDetails',
            method: HttpMethod.Put,
            data: addPersonalDetailsRequest
        }),
    }),
    getPersonalAndEmploymentDetails: builder.query({
        /**
         * @param {PersonalAndEmploymentDetailsRequest} personalAndEmploymentDetailsRequest
         */
        query: (personalAndEmploymentDetailsRequest) => ({
            url: `/Account/GetPersonalAndEmploymentDetails?email=${encodeURIComponent(personalAndEmploymentDetailsRequest.email)}`,
            method: HttpMethod.Get
        }),
        /**
         * @returns {GetPersonalAndEmploymentDetailsDTO}
         */
        transformResponse: (response) => response,
    }),
    getAddresses: builder.query({
        /**
         * @param {AddressesRequest} addressesRequest
         */
        query: (addressesRequest) => ({
            url: `/Account/GetAddressesByEmail/${encodeURIComponent(addressesRequest.email)}`,
            method: HttpMethod.Get
        }),
        /**
         * @returns {AddressEntryDTO[]}
         */
        transformResponse: (response) => {
            if (!(response instanceof Array)) {
                return [];
            }
            return response;
        },
    }),
    updateAddress: builder.mutation({
        /**
         * @param {AddressEntryDTO} updateAddressRequest
         */
        query: (updateAddressRequest) => ({
            url: '/Account/UpdateAddress',
            method: HttpMethod.Post,
            data: updateAddressRequest
        }),
        /**
         * @returns {Object}
         */
        transformResponse: (response) => ({ id: response }),
    }),
    updateContact: builder.mutation({
        query: (emailRequest) => ({
            url: '/Account/UpdateContact',
            method: HttpMethod.Post,
            data: emailRequest
        }),
    }),
    getContactByEmail: builder.query({
        query: (getContactByEmailRequest) => ({
            url: `/Account/GetContactByEmail/${encodeURIComponent(getContactByEmailRequest.email)}`,
            method: HttpMethod.Get
        }),
    }),
    getEmployerCodeByEmail: builder.query({
        /**
         * @param {EmployerCodeRequest} employerCodeRequest
         */
        query: (employerCodeRequest) => ({
            url: `/Account/GetEmployerCodeByEmail?email=${encodeURIComponent(employerCodeRequest.email)}`,
            method: HttpMethod.Get
        }),
        /**
         * @returns {string}
         */
        transformResponse: (response) => response,
    }),

});
