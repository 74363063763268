import React, { useEffect, useState } from 'react';
import { Box, Typography, Grid, Button, Container, Paper, Alert } from '@mui/material';
import useCustomTranslation from "../../hooks/useCustomTranslation";
import LoadingSpinner from "../LoadingSpinner";
import { useGetPersonalAndEmploymentDetailsQuery, useAddPersonalDetailsMutation, useGetContactByEmailQuery, useUpdateContactMutation } from "../../reducers/enrollmentApiSlice";
import dayjs from "dayjs";
import AccountInformationFormFields from "../AccountInformation/AccountInformationFormFields";

const EditAccountInformation = ({ userEmail, employerCode, onEnrollmentComplete }) => {

    const { translate } = useCustomTranslation();

    const [formState, setFormState] = useState({
        firstName: '',
        middleName: '',
        lastName: '',
        phoneNumber: '',
        ssn: '',
        dob: '',
        maritalStatus: '',
        gender: '',
        email: '',
        dateError: '',
        successMessage: '',
        errorMessage: '',
        messages: [],
        employmentStatus: '',
        inputEmployerCode: employerCode || ''
    });

    const [isLoading, setIsLoading] = useState(true);

    const { data: personalDetails, isLoading: isPersonalDetailsLoading, refetch: refetchPersonalDetails } = useGetPersonalAndEmploymentDetailsQuery({ email: userEmail, employerCode });
    const { data: contactDetails, isLoading: isContactDetailsLoading, refetch: refetchContactDetails } = useGetContactByEmailQuery({ email: userEmail, employerCode });
    const [addPersonalDetails] = useAddPersonalDetailsMutation();
    const [updateContact] = useUpdateContactMutation();

    useEffect(() => {
        if (personalDetails) {
            setFormState(prevState => {
                const newState = {
                    ...prevState,
                    firstName: personalDetails.firstName || '',
                    middleName: personalDetails.middleName || '',
                    lastName: personalDetails.lastName || '',
                    ssn: personalDetails.socialSecurityNumber || '',
                    dob: personalDetails.dateOfBirth || '',
                    gender: personalDetails.genderId !== null ? personalDetails.genderId.toString() : '',
                    maritalStatus: personalDetails.maritalStatusId !== null ? personalDetails.maritalStatusId.toString() : '',
                    email: personalDetails.email || '',
                    employmentStatus: personalDetails.employmentStatusId !== null ? personalDetails.employmentStatusId.toString() : ''
                };
                return newState;
            });
        }
    }, [personalDetails]);

    useEffect(() => {
        if (contactDetails) {
            setFormState(prevState => {
                const newState = {
                    ...prevState,
                    phoneNumber: contactDetails.phoneNumber || ''
                };
                return newState;
            });
        }
    }, [contactDetails]);

    useEffect(() => {
        refetchPersonalDetails();
        refetchContactDetails();
    }, [refetchPersonalDetails, refetchContactDetails]);

    useEffect(() => {
        if (!isPersonalDetailsLoading && !isContactDetailsLoading) {
            setIsLoading(false);
        }
    }, [isPersonalDetailsLoading, isContactDetailsLoading]);

    const handleFormSubmit = async (event) => {
        event.preventDefault();

        const formattedDob = dayjs(formState.dob).isValid() ? dayjs(formState.dob).format('YYYY-MM-DD') : '';

        const formData = {
            email: userEmail,
            employerCode: employerCode,
            genderId: formState.gender,
            dateOfBirth: formattedDob,
            employmentStatusId: formState.employmentStatus,
            maritalStatusId: formState.maritalStatus,
            socialSecurityNumber: formState.ssn,
            firstName: formState.firstName,
            middleName: formState.middleName,
            lastName: formState.lastName
        };


        try {
            const result = await addPersonalDetails(formData).unwrap();
            setFormState(prevState => ({
                ...prevState,
                successMessage: translate('Employee information updated successfully.'),
                errorMessage: '',
                messages: [translate('Employee information updated successfully.')]
            }));
        } catch (error) {
            console.error('Error updating personal details:', error);
            setFormState(prevState => ({
                ...prevState,
                errorMessage: translate('An error occurred. Please try again later.'),
                successMessage: '',
                messages: [translate('An error occurred. Please try again later.')]
            }));
        }

        const contactData = {
            email: userEmail,
            employerCode: employerCode,
            phoneNumber: formState.phoneNumber,
            contactType: "Work",
            preferred: true
        };


        try {
            const result = await updateContact(contactData).unwrap();
            setFormState(prevState => ({
                ...prevState,
                successMessage: translate('Contact information updated successfully.'),
                errorMessage: '',
                messages: [translate('Contact information updated successfully.')]
            }));
            if (onEnrollmentComplete) {
                onEnrollmentComplete();
            }
        } catch (error) {
            console.error('Error updating contact details:', error);
            setFormState(prevState => ({
                ...prevState,
                errorMessage: translate('An error occurred while updating contact. Please try again later.'),
                successMessage: '',
                messages: [translate('An error occurred while updating contact. Please try again later.')]
            }));
        }
    };

    if (isLoading) {
        return <LoadingSpinner />;
    }


    return (
        <Container maxWidth="md" sx={{ my: 4 }}>
            <Paper elevation={3} sx={{ p: 3 }}>
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    {!isLoading && formState.errorMessage && (
                        <Alert severity="error" sx={{ width: '100%', marginBottom: 2 }}>
                            <Typography variant="body2">{formState.errorMessage}</Typography>
                        </Alert>
                    )}
                    {!isLoading && formState.successMessage && (
                        <Alert severity="success" sx={{ width: '100%', marginBottom: 2 }}>
                            <Typography variant="body2">{formState.successMessage}</Typography>
                        </Alert>
                    )}
                    <form onSubmit={handleFormSubmit}>
                        <AccountInformationFormFields
                            firstName={formState.firstName}
                            setFirstName={(value) => setFormState(prevState => ({ ...prevState, firstName: value }))}
                            middleName={formState.middleName}
                            setMiddleName={(value) => setFormState(prevState => ({ ...prevState, middleName: value }))}
                            lastName={formState.lastName}
                            setLastName={(value) => setFormState(prevState => ({ ...prevState, lastName: value }))}
                            socialSecurityNumber={formState.ssn}
                            setSocialSecurityNumber={(value) => setFormState(prevState => ({ ...prevState, ssn: value }))}
                            gender={formState.gender}
                            setGender={(value) => setFormState(prevState => ({ ...prevState, gender: value }))}
                            maritalStatus={formState.maritalStatus}
                            setMaritalStatus={(value) => setFormState(prevState => ({ ...prevState, maritalStatus: value }))}
                            email={formState.email}
                            setEmail={(value) => setFormState(prevState => ({ ...prevState, email: value }))}
                            phoneNumber={formState.phoneNumber}
                            setPhoneNumber={(value) => setFormState(prevState => ({ ...prevState, phoneNumber: value }))}
                            dateOfBirth={formState.dob}
                            setDateOfBirth={(value) => setFormState(prevState => ({ ...prevState, dob: value }))}
                            dateError={formState.dateError}
                            setDateError={(value) => setFormState(prevState => ({ ...prevState, dateError: value }))}
                            messages={formState.messages}
                            employmentStatus={formState.employmentStatus}
                            setEmploymentStatus={(value) => setFormState(prevState => ({ ...prevState, employmentStatus: value }))}
                        />
                        <Grid container spacing={3} sx={{ mt: 2 }}>
                            <Grid item xs={12}>
                                <Button type="submit" variant="contained" color="primary" fullWidth>
                                    {translate('Submit')}
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                </Box>
            </Paper>
        </Container>
    );
};

export default EditAccountInformation;