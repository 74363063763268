import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
    Container,
    Box,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Typography,
    useTheme,
    useMediaQuery
} from '@mui/material';
import { useDispatch } from 'react-redux';
import { useGetAllCarriersQuery } from '../../reducers/enrollmentApiSlice';
import useCustomTranslation from '../../hooks/useCustomTranslation';
import { setSelectedCarrier, setSelectedPlan } from '../../reducers/createInsurancePlanSlice';
import LoadingSpinner from '../LoadingSpinner';

const CarrierDropdown = ({ onSelectionChange }) => {
    const { data: carriers, error, isLoading } = useGetAllCarriersQuery();
    const [selectedCarrierId, setSelectedCarrierId] = useState('');
    const { translate } = useCustomTranslation();
    const dispatch = useDispatch();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    useEffect(() => {
        if (onSelectionChange) {
            onSelectionChange(selectedCarrierId);
        }
        if (selectedCarrierId && carriers) {
            const selectedCarrier = carriers.find(carrier => carrier.carrierId === parseInt(selectedCarrierId));
            dispatch(setSelectedCarrier(selectedCarrier));
            dispatch(setSelectedPlan(null));
        }
    }, [selectedCarrierId, onSelectionChange, dispatch, carriers]);

    const handleDropdownChange = (event) => {
        const newSelectedCarrierId = event.target.value;
        setSelectedCarrierId(newSelectedCarrierId);
    };

    return (
        <Container component="main" maxWidth="md">
            <Box sx={{
                my: 4,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                width: '100%'
            }}>
                {isLoading && <LoadingSpinner />}
                {error && (
                    <Typography color="error" role="alert">
                        {translate('Failed to load carriers')}
                    </Typography>
                )}
                {carriers && carriers.length > 0 && (
                    <FormControl fullWidth>
                        <InputLabel id="carrier-select-label">
                            {translate('Select Carrier')}
                        </InputLabel>
                        <Select
                            labelId="carrier-select-label"
                            id="carrier-select"
                            value={selectedCarrierId}
                            onChange={handleDropdownChange}
                            label={translate('Select Carrier')}
                            sx={{ minWidth: isMobile ? '100%' : 300 }}
                        >
                            {carriers.map((carrier) => (
                                <MenuItem key={carrier.carrierId} value={carrier.carrierId.toString()}>
                                    {carrier.carrierName}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                )}
            </Box>
        </Container>
    );
};

CarrierDropdown.propTypes = {
    onSelectionChange: PropTypes.func.isRequired,
};

export default CarrierDropdown;