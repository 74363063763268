import React, { useState } from 'react';
import { Button, Typography, Box, Modal, Snackbar, Alert, TextField, Grid } from '@mui/material';

import PropTypes from 'prop-types';
import { useCreateAccountMutation } from '../../reducers/enrollmentApiSlice';
import {styled} from "@mui/system";

const ModalContent = styled('div')(({ theme }) => ({
    fontWeight: 500,
    textAlign: 'start',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
    overflow: 'hidden',
    backgroundColor: theme.palette.mode === 'dark' ? '#000' : '#fff',
    borderRadius: '8px',
    border: `1px solid ${theme.palette.mode === 'dark' ? '#333' : '#ccc'}`,
    boxShadow: `0 4px 12px ${theme.palette.mode === 'dark' ? 'rgb(0 0 0 / 0.5)' : 'rgb(0 0 0 / 0.2)'}`,
    padding: '24px',
    color: theme.palette.mode === 'dark' ? '#ccc' : '#000',
}));

const CreateEmployeeModal = ({ employerCode, translate, onSuccess }) => {
    const [open, setOpen] = useState(false);
    const [alert, setAlert] = useState({ open: false, message: '', severity: 'success' });
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        employerCode: employerCode
    });
    const [createAccount, { isLoading }] = useCreateAccountMutation();

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await createAccount({ ...formData }).unwrap();
            setAlert({ open: true, message: 'Employee created successfully.', severity: 'success' });
            handleClose();
            onSuccess();  // Call the onSuccess handler to refresh the data grid
        } catch (error) {
            setAlert({ open: true, message: 'Failed to create employee.', severity: 'error' });
        }
    };

    const handleAlertClose = () => {
        setAlert({ ...alert, open: false });
    };

    return (
        <>
            <Button variant="contained" color="primary" onClick={handleOpen}>
                {translate('Create New Employee')}
            </Button>
            <Modal
                open={open}
                onClose={handleClose}
            >
                <ModalContent>
                    <Typography variant="h6" className="modal-title">
                        {translate('Create New Employee')}
                    </Typography>
                    <form onSubmit={handleSubmit}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextField
                                    label={translate('First Name')}
                                    name="firstName"
                                    value={formData.firstName}
                                    onChange={handleChange}
                                    fullWidth
                                    required
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    label={translate('Last Name')}
                                    name="lastName"
                                    value={formData.lastName}
                                    onChange={handleChange}
                                    fullWidth
                                    required
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    label={translate('Email')}
                                    name="email"
                                    type="email"
                                    value={formData.email}
                                    onChange={handleChange}
                                    fullWidth
                                    required
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    label={translate('Employer Code')}
                                    name="employerCode"
                                    value={formData.employerCode}
                                    onChange={handleChange}
                                    fullWidth
                                    required
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    fullWidth
                                    disabled={isLoading}
                                >
                                    {translate('Submit')}
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                </ModalContent>
            </Modal>
            <Snackbar open={alert.open} autoHideDuration={6000} onClose={handleAlertClose}>
                <Alert onClose={handleAlertClose} severity={alert.severity} sx={{ width: '100%' }}>
                    {alert.message}
                </Alert>
            </Snackbar>
        </>
    );
};

CreateEmployeeModal.propTypes = {
    employerCode: PropTypes.string.isRequired,
    translate: PropTypes.func.isRequired,
    onSuccess: PropTypes.func.isRequired, // add prop types for the success handler
};

export default CreateEmployeeModal;
