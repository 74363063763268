import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { DataGrid } from '@mui/x-data-grid';
import {
    CircularProgress,
    Typography,
    Container,
    Checkbox,
    IconButton,
    TextField,
    Box,
    Snackbar,
    Alert, Button
} from '@mui/material';
import { useGetEmployeesByEmployerCodeQuery, useGetAllEmployeeStatusesQuery, useGetMaritalStatusesQuery, useGetEmployerAdminsByCodeQuery, useCreateEmployerAdminMutation, useRemoveEmployerAdminMutation } from '../../reducers/enrollmentApiSlice';
import useCustomTranslation from '../../hooks/useCustomTranslation';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import './EmployeeDataGrid.css';

const EmployeeDataGrid = ({ employerCode, currentUserEmail, onSuccess }) => {
    const { translate } = useCustomTranslation();
    const { data: employeeData, error, isLoading: isEmployeesLoading, refetch } = useGetEmployeesByEmployerCodeQuery(employerCode, {
        skip: !employerCode,
    });

    const { data: employeeStatuses = [], isLoading: isEmployeeStatusesLoading } = useGetAllEmployeeStatusesQuery();
    const { data: maritalStatuses = [], isLoading: isMaritalStatusesLoading } = useGetMaritalStatusesQuery();
    const { data: adminResponse, isLoading: isAdminsLoading } = useGetEmployerAdminsByCodeQuery(employerCode, {
        skip: !employerCode,
    });

    const adminData = adminResponse?.data || [];

    const [createEmployerAdmin, { isLoading: isCreatingAdmin }] = useCreateEmployerAdminMutation();
    const [removeEmployerAdmin, { isLoading: isRemovingAdmin }] = useRemoveEmployerAdminMutation();
    const [alert, setAlert] = useState({ open: false, message: '', severity: 'success' });
    const [rows, setRows] = useState([]);
    const navigate = useNavigate(); // Initialize useNavigate

    useEffect(() => {
        if (employeeData) {
            const uniqueAdminEmails = [...new Set(adminData.map(admin => admin.email))];
            const updatedRows = employeeData.map(emp => ({
                id: emp.employeeId,
                ...emp.personDetails,
                ...emp,
                isAdmin: uniqueAdminEmails.includes(emp.email),
                isCurrentUser: emp.email === currentUserEmail
            }));
            setRows(updatedRows);
        }
    }, [employeeData, adminData, currentUserEmail]);

    const handleAlertClose = () => {
        setAlert({ ...alert, open: false });
    };

    const handleTerminateEmployee = (employee) => {
        if (window.confirm(translate('Are you sure you want to terminate this employee?'))) {
        }
    };

    const handleAdminCheckboxChange = async (employee, event) => {
        const isChecked = event.target.checked;

        if (isChecked) {
            try {
                await createEmployerAdmin({ email: employee.email, employerCode }).unwrap();
                setAlert({ open: true, message: 'Employer admin created successfully.', severity: 'success' });

                // Update the row to reflect the new admin status
                setRows(prevRows =>
                    prevRows.map(row =>
                        row.id === employee.employeeId ? { ...row, isAdmin: true } : row
                    )
                );

                // Call the success handler
                onSuccess();
            } catch (error) {
                console.error('Failed to create employer admin:', error);
                setAlert({ open: true, message: 'Failed to create employer admin.', severity: 'error' });
            }
        } else {
            try {
                await removeEmployerAdmin({ email: employee.email, employerCode }).unwrap();
                setAlert({ open: true, message: 'Employer admin removed successfully.', severity: 'success' });

                // Update the row to reflect the new admin status
                setRows(prevRows =>
                    prevRows.map(row =>
                        row.id === employee.employeeId ? { ...row, isAdmin: false } : row
                    )
                );

                // Call the success handler
                onSuccess();
            } catch (error) {
                console.error('Failed to remove employer admin:', error);
                setAlert({ open: true, message: 'Failed to remove employer admin.', severity: 'error' });
            }
        }
    };

    const handleBenefitEnrollments = (employee) => {
    };

    const handleInputChange = (id, field, value) => {
        setRows(prevRows => prevRows.map(row =>
            row.id === id ? { ...row, [field]: value } : row
        ));
    };

    const getStatusNameById = (statuses, id, isMarital) => {
        const status = isMarital
            ? statuses.find(status => status.maritalStatusId === id)
            : statuses.find(status => status.id === id);
        return status ? (isMarital ? status.maritalStatusName : status.name) : id;
    };

    const handleEditEmployee = (employee) => {
        navigate(`/edit-account-information/${employee.email}`, { state: { selectedEmployee: employee } }); // Navigate to the new component
    };

    const columns = [
        { field: 'employeeId', headerName: translate('ID'), width: 90 },
        { field: 'firstName', headerName: translate('First name'), width: 150 },
        { field: 'lastName', headerName: translate('Last name'), width: 150 },
        {
            field: 'dateOfHire',
            headerName: translate('Date of Hire'),
            width: 200,
            renderCell: (params) => (
                <TextField
                    type="date"
                    value={params.value}
                    fullWidth
                    inputProps={{ 'aria-label': translate('Date of Hire'), readOnly: true }}
                />
            ),
        },
        {
            field: 'employeeStatusId',
            headerName: translate('Status'),
            width: 150,
            renderCell: (params) => (
                <Typography>{getStatusNameById(employeeStatuses, params.value, false)}</Typography>
            ),
        },
        {
            field: 'maritalStatusId',
            headerName: translate('Marital Status'),
            width: 150,
            renderCell: (params) => (
                <Typography>{getStatusNameById(maritalStatuses, params.value, true)}</Typography>
            ),
        },
        { field: 'email', headerName: translate('Email'), width: 200 },
        {
            field: 'admin',
            headerName: translate('Admin'),
            width: 150,
            renderCell: (params) => (
                <Checkbox
                    checked={params.row.isAdmin}
                    onChange={(event) => handleAdminCheckboxChange(params.row, event)}
                    aria-label={translate('Make Admin')}
                />
            ),
        },
        {
            field: 'benefits',
            headerName: translate('Benefit Enrollments'),
            width: 200,
            renderCell: (params) => (
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleBenefitEnrollments(params.row)}
                    aria-label={translate('View Benefits')}
                >
                    {translate('View Benefits')}
                </Button>
            ),
        },
        {
            field: 'edit',
            headerName: translate('Edit Employee'),
            width: 150,
            renderCell: (params) => (
                <IconButton
                    color="primary"
                    onClick={() => handleEditEmployee(params.row)}
                    aria-label={translate('Edit Employee')}
                >
                    <EditIcon />
                </IconButton>
            ),
        },
        {
            field: 'terminate',
            headerName: translate('Terminate Employee'),
            width: 150,
            renderCell: (params) => (
                <IconButton
                    color="secondary"
                    onClick={() => handleTerminateEmployee(params.row)}
                    aria-label={translate('Terminate Employee')}
                    style={{ color: 'red' }}
                >
                    <DeleteIcon />
                </IconButton>
            ),
        },
    ];

    useEffect(() => {
        if (onSuccess) {
            refetch();
        }
    }, [onSuccess, refetch]);

    return (
        <Container>
            {(isEmployeesLoading || isEmployeeStatusesLoading || isMaritalStatusesLoading || isAdminsLoading || isCreatingAdmin || isRemovingAdmin) && <CircularProgress aria-label={translate('Loading')} />}
            {error && <Alert severity="error" aria-label={translate('Error')}>{error.message}</Alert>}
            <Box mt={2}>
                <DataGrid
                    rows={rows}
                    columns={columns}
                    pageSize={5}
                    rowsPerPageOptions={[5]}
                    disableSelectionOnClick
                    autoHeight
                    getRowClassName={(params) =>
                        params.row.isCurrentUser ? 'current-user-row' :
                            params.row.isAdmin ? 'admin-row' : ''
                    }
                    aria-label={translate('Employee Data Grid')}
                />
            </Box>
            <Snackbar open={alert.open} autoHideDuration={6000} onClose={handleAlertClose}>
                <Alert onClose={handleAlertClose} severity={alert.severity} sx={{ width: '100%' }}>
                    {alert.message}
                </Alert>
            </Snackbar>
        </Container>
    );
};

EmployeeDataGrid.propTypes = {
    employerCode: PropTypes.string.isRequired,
    currentUserEmail: PropTypes.string.isRequired,
    onSuccess: PropTypes.func.isRequired,
};

export default EmployeeDataGrid;
