import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    accountPage: { completed: false, inProgress: false },
    dependentPage: { completed: false, inProgress: false },
    addressPage: { completed: false, inProgress: false },
    planSelectionPage: { completed: false, inProgress: false },
    medicalInfoPage: { completed: false, inProgress: false },
    reviewPage: { completed: false, inProgress: false },
    agreeAndSignPage: { completed: false, inProgress: false },
    enrollmentStatus: 'idle',
    error: null,
    formData: {},
    dependentsData: [],
    addressesData: [],
    waiverReasons: {},
    selectedPlansWithDependents: [],
    employmentDetailsPage: { completed: false, inProgress: false },
    employmentDetailsData: {},
};

const benefitEnrollmentSlice = createSlice({
    name: 'benefitEnrollment',
    initialState,
    reducers: {
        startAccountPage(state) {
            state.accountPage.inProgress = true;
        },
        completeAccountPage(state) {
            state.accountPage.completed = true;
            state.accountPage.inProgress = false;
        },
        startDependentPage(state) {
            state.dependentPage.inProgress = true;
        },
        completeDependentPage(state) {
            state.dependentPage.completed = true;
            state.dependentPage.inProgress = false;
        },
        startAddressPage(state) {
            state.addressPage.inProgress = true;
        },
        completeAddressPage(state) {
            state.addressPage.completed = true;
            state.addressPage.inProgress = false;
        },
        startPlanSelectionPage(state) {
            state.planSelectionPage.inProgress = true;
        },
        completePlanSelectionPage(state) {
            state.planSelectionPage.completed = true;
            state.planSelectionPage.inProgress = false;
        },
        startMedicalInfoPage(state) {
            state.medicalInfoPage.inProgress = true;
        },
        completeMedicalInfoPage(state) {
            state.medicalInfoPage.completed = true;
            state.medicalInfoPage.inProgress = false;
        },
        startReviewPage(state) {
            state.reviewPage.inProgress = true;
        },
        completeReviewPage(state) {
            state.reviewPage.completed = true;
            state.reviewPage.inProgress = false;
        },
        startAgreeAndSignPage(state) {
            state.agreeAndSignPage.inProgress = true;
        },
        completeAgreeAndSignPage(state) {
            state.agreeAndSignPage.completed = true;
            state.agreeAndSignPage.inProgress = false;
        },
        enrollBenefitStart(state) {
            state.enrollmentStatus = 'loading';
        },
        enrollBenefitSuccess(state) {
            state.enrollmentStatus = 'succeeded';
        },
        enrollBenefitFailure(state, action) {
            state.enrollmentStatus = 'failed';
            state.error = action.payload;
        },
        resetEnrollmentState(state) {
            Object.assign(state, initialState);
        },
        updateFormData(state, action) {
            state.formData = action.payload;
        },
        updateDependentsData(state, action) {
            state.dependentsData = action.payload;
        },
        updateAddressesData(state, action) {
            state.addressesData = action.payload;
        },
        updateWaiverReason(state, action) {
            const { planId, reason } = action.payload;
            state.waiverReasons[planId] = reason;
        },
        updateSelectedPlansWithDependents(state, action) {
            state.selectedPlansWithDependents = action.payload;
        },
        startEmploymentDetailsPage(state) {
            state.employmentDetailsPage.inProgress = true;
        },
        completeEmploymentDetailsPage(state) {
            state.employmentDetailsPage.completed = true;
            state.employmentDetailsPage.inProgress = false;
        },
        updateEmploymentDetailsData(state, action) {
            state.employmentDetailsData = action.payload;
        },

    },
});

const { actions, reducer } = benefitEnrollmentSlice;

export const {
    startAccountPage,
    completeAccountPage,
    startDependentPage,
    completeDependentPage,
    startAddressPage,
    completeAddressPage,
    startPlanSelectionPage,
    completePlanSelectionPage,
    startMedicalInfoPage,
    completeMedicalInfoPage,
    startReviewPage,
    completeReviewPage,
    startAgreeAndSignPage,
    completeAgreeAndSignPage,
    enrollBenefitStart,
    enrollBenefitSuccess,
    enrollBenefitFailure,
    resetEnrollmentState,
    updateFormData,
    updateDependentsData,
    updateAddressesData,
    updateWaiverReason,
    updateSelectedPlansWithDependents,
    startEmploymentDetailsPage,
    completeEmploymentDetailsPage,
    updateEmploymentDetailsData,

} = actions;

export default reducer;