import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useCreateEmployerAdminMutation, useCreateAccountMutation } from '../../reducers/enrollmentApiSlice';
import { completeEmployerSignup, clearEmployerSignupInfo } from '../../reducers/employerSignupSlice';
import useCustomTranslation from '../../hooks/useCustomTranslation';
import NewAccountForm from '../NewAccount/NewAccountForm';
import AuthTokenService from '../../services/AuthTokenService';
import { setAuthentication } from '../../reducers/authReducer';
import { logInfo, logError } from '../../utilities/Logger';
import { Message, MessageType } from '../../types/message';
import { useNavigate } from 'react-router-dom';
import useEmployerSignupInfo from '../../hooks/useEmployerSignupInfo'; // Import the custom hook

const NewEmployerAdminAccountFormContainer = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { translate } = useCustomTranslation();
    const { email, employerCode } = useEmployerSignupInfo(); // Use the custom hook to get the employer signup info

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [messages, setMessages] = useState([]);
    const [createEmployerAdmin] = useCreateEmployerAdminMutation();
    const [createAccount, { isLoading, isError, data }] = useCreateAccountMutation();

    useEffect(() => {
        if (!email || !employerCode) {
            logError('Employer signup information is missing');
        }
    }, [email, employerCode]);

    const handleSubmit = async (event) => {
        event.preventDefault();

        // Step 1: Create the account
        const createAccountPayload = {
            email: email,
            employerCode: employerCode,
            firstName: firstName,
            lastName: lastName,
        };

        try {
            const createAccountResponse = await createAccount(createAccountPayload).unwrap();

            if (createAccountResponse) {

                const { isAuthenticated, user, authToken, claims } = AuthTokenService.getAuthInfo();

                AuthTokenService.setAuthInfo({
                    isAuthenticated: isAuthenticated,
                    user: user,
                    authToken: authToken,
                    claims: claims ?? [],
                });

                dispatch(setAuthentication({
                    isAuthenticated: isAuthenticated,
                    user: user,
                    authToken: authToken,
                    claims: claims ?? []
                }));

            }
        } catch (error) {
            logError(`Register failed: ${error.message}`, 'red');
            logError(`Error details: Status: ${error.response?.status}, Data: ${JSON.stringify(error.response?.data)}`, 'red');
            return; // Exit if account creation fails
        }

        // Step 2: Create the employer admin
        try {
            const response = await createEmployerAdmin({ email: email, employerCode }).unwrap();
            setMessages([new Message('Employer admin created successfully', MessageType.Success)]);
            dispatch(completeEmployerSignup());
            dispatch(clearEmployerSignupInfo()); // Clear signup info only after the process is complete
            navigate('/home'); // Navigate to the next route
        } catch (error) {
            logError(`Creating employer admin failed: ${error.message}`);
            setMessages([new Message(error.data || 'Creating employer admin failed', MessageType.Error)]);
        }
    };

    if (isLoading) {
        return <div>{translate('Loading...')}</div>;
    }

    if (!email || !employerCode) {
        return <div>{translate('Employer signup information is missing.')}</div>;
    }

    return (
        <NewAccountForm
            firstName={firstName}
            setFirstName={setFirstName}
            lastName={lastName}
            setLastName={setLastName}
            employerCode={employerCode}
            setEmployerCode={() => {}} // No-op since the field is read-only
            onSubmit={handleSubmit}
            translate={translate}
            isReadOnly={true} // Make the employer code field read-only
        />
    );
};

export default NewEmployerAdminAccountFormContainer;
