import React from 'react';
import { Box, Grid, Paper, useTheme, Typography } from '@mui/material';
import { ContactMail, Store } from '@mui/icons-material';
import useCustomTranslation from '../hooks/useCustomTranslation';
import PolicyBasedAccess from '../components/PolicyBasedAccess';
import { Policy } from '../types/policy';
import Branding from '../components/Branding';
import logoImage from '../assets/logo/default_logo.png';
import useEmployerSignupInfo from '../hooks/useEmployerSignupInfo';
import NewEmployerAdminAccountFormContainer from '../components/Employer/NewEmployerAdminAccountFormContainer';
import groupImage from '../assets/images/GroupOfPeople.png';
import DashboardLink from "../components/DashboardLink";
import {gowellColors} from "../themes/palettes/gowellColors";


const HomePage = () => {
    const theme = useTheme();
    const { translate } = useCustomTranslation();
    const { email, employerCode } = useEmployerSignupInfo();

    return (
        <Box
            sx={{
                position: 'relative',
                display: 'flex',
                flexDirection: 'column',
                gap: { xs: 3, sm: 4 },
                p: { xs: 3, md: 5 },
                minHeight: '100vh',
                alignItems: 'center',
                justifyContent: 'center',
                width: '100%',
                mx: 'auto',
                '::after': {
                    content: '""',
                    backgroundImage: `url(${groupImage})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    opacity: 0.3,
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    position: 'absolute',
                    zIndex: -1,
                },
            }}
        >
            <Paper
                sx={{
                    backgroundColor: 'rgba(255, 255, 255, 0.3)',
                    padding: 4,
                    borderRadius: 2,
                    width: '100%',
                    maxWidth: '1200px',
                }}
            >
                <Branding
                    logoUrl={logoImage}
                    width="200px"
                    height="60px"
                    style={{ marginBottom: theme.spacing(4) }}
                />

                {email && employerCode ? (
                    <NewEmployerAdminAccountFormContainer />
                ) : (
                    <>
                        <Typography variant="h4" align="center" gutterBottom>
                            {translate('Welcome to Your Benefits Portal')}
                        </Typography>
                        <Grid container spacing={3} justifyContent="center">
                            <PolicyBasedAccess
                                policy={Policy.User}
                                notPolicy={Policy.BrokerOrGlobalAdmin.concat(Policy.Agency)}
                            >
                                <Grid item xs={12} sm={6} md={4}>
                                    <DashboardLink
                                        title={'Insurance Enrollment'}
                                        description={'Start your insurance enrollment process now.'}
                                        link={'/benefit-enrollment'}
                                        icon={<ContactMail />}
                                        buttonColor={gowellColors.pointilSociety.base}
                                        buttonText={'Click Here to Enroll'}
                                    />

                                </Grid>
                            </PolicyBasedAccess>

                            <PolicyBasedAccess policy={Policy.GlobalAdminAgentEmployerAdminBroker}>
                                <Grid item xs={12} sm={6} md={4}>
                                    <DashboardLink
                                        title={'Company Portal'}
                                        description={'Edit company information, manage employees, and more.'}
                                        link={'/admin/employer-administrator-portal'}
                                        icon={<Store />}
                                    />
                                </Grid>
                            </PolicyBasedAccess>

                            <PolicyBasedAccess policy={Policy.GlobalAdmin}>
                                <Grid item xs={12} sm={6} md={4}>
                                    <DashboardLink
                                        title={'Broker Portal'}
                                        description={'Edit plan information, manage companies, and more.'}
                                        link={'/broker-portal'}
                                        icon={<Store />}
                                    />
                                </Grid>
                            </PolicyBasedAccess>
                        </Grid>
                    </>
                )}
            </Paper>
        </Box>
    );
};

export default HomePage;